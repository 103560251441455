import { gql, useQuery } from '@apollo/client'
import { ActivePatient, GraphQLResult } from '../types'

export const QUERY_ACTIVE_PATIENT = gql`
  query QueryActivePatient($patientId: ID!) {
    result: activePatient(id: $patientId) {
      id
      devices {
        id
        aggregates {
          name
          value
          updated
        }
      }
    }
  }
`
export const useActivePatient = (patientId: string): ActivePatient | undefined => {
  const { loading, data, error } = useQuery<GraphQLResult<ActivePatient>>(QUERY_ACTIVE_PATIENT, {
    variables: { patientId },
  })
  if (!loading && !error && data) {
    return data.result || undefined
  }
  if (error) {
    console.warn(error)
  }
  return undefined
}
