const subscribe = (topic: string, listener: EventListener) => {
  document.addEventListener(topic, listener)
  return () => {
    document.removeEventListener(topic, listener)
  }
}

const publish = <T = any>(topic: string, payload?: T) => {
  const event = new CustomEvent<T>(topic, { detail: payload })
  document.dispatchEvent(event)
}

export const customEvents = {
  onChartHover: 'CHART_HOVER',
  onChartLeave: 'CHART_LEAVE',
  onAxisSetExtreme: 'AXIS_SET_EXTREME',
  onVisibleRangeChanged: 'AXIS_VISIBLE_RANGE_CHANGED',
  onZoom: 'CHART_ZOOM',
  onToolbarButtonClick: 'TOOLBAR_BUTTON_CLICK',
  onChartSelect: 'CHART_SELECTION_EVENT',
  shiftLeft: 'SHIFT_LEFT',
  shiftRight: 'SHIFT_RIGHT',
  calibrateTimeAxis: 'CALIBRATE_TIME_AXIS',
  // chartAttached: 'CHART_ATTACHED',
  subscribe,
  publish,
}
