import { ApolloClient, InMemoryCache, ApolloProvider, InMemoryCacheConfig, ApolloLink } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { onError } from '@apollo/client/link/error'
import React, { FC, ReactElement } from 'react'
import { createClient } from 'graphql-ws'
import jwt_decode from 'jwt-decode'
import { useAuthToken, useInstanceSetting } from '.'
import { SentryLink } from 'apollo-link-sentry'
import * as Sentry from '@sentry/react'
import * as SentryCore from '@sentry/core'
import { extractDefinition } from 'apollo-link-sentry/lib-cjs/operation'

const attachTokenToWebSocket = (token: string) => {
  return class AttachTokenWebSocket extends WebSocket {
    constructor(url: string | URL, protocols?: string | string[]) {
      super(url, protocols ? [token].concat(protocols) : [token])
    }
  }
}

export const cachePolicy: InMemoryCacheConfig = {
  typePolicies: {
    ActiveDevice: {
      keyFields: false,
    },
    NoteChangeEvent: {
      keyFields: false,
    },
    Device: {
      keyFields: ['id'],
    },
    Subscription: {
      fields: {
        wardActivePatientsEvents: {
          merge: false,
        },
      },
    },
  },
}

const reloadIfExpired = (tokenExpiry: number) => {
  if (new Date().getTime() > tokenExpiry) {
    window.location.reload()
  }
}

export const DefaultApolloProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const endpoint = useInstanceSetting()?.apiEndpoint
  const token = useAuthToken()
  const tokenExpiry = jwt_decode<{ exp: number }>(token).exp * 1000

  const wsClient = createClient({
    url: `wss://${endpoint}/graphql`,
    retryAttempts: Infinity,
    connectionAckWaitTimeout: 30000,
    webSocketImpl: attachTokenToWebSocket(token),
    shouldRetry: () => {
      reloadIfExpired(tokenExpiry)
      return true
    },
  })
  wsClient.on('connecting', () => {
    console.debug('WS Connecting...')
  })
  wsClient.on('connected', (event) => {
    console.debug('WS Connected:', event)
  })
  wsClient.on('closed', (event) => {
    console.debug('WS Closed:', event)
  })
  wsClient.on('error', (event) => {
    console.debug('WS Error:', event)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.warn('GraphQL errors:', graphQLErrors)
    }
    if (networkError) {
      console.warn('Network error:', networkError)
    }

    reloadIfExpired(tokenExpiry)
  })

  // Link for separating each graphql operation into its own span for downstream tracing
  const sentryTrace = new ApolloLink((operation, forward) => {
    const observer = forward(operation)

    const name = extractDefinition(operation).name
    Sentry.startSpanManual({ name: name?.value ?? '', op: 'http.graphql' }, (span) => {
      if (span) {
        operation.extensions['sentry-trace'] = SentryCore.spanToTraceHeader(span)
        operation.extensions['baggage'] = SentryCore.getDynamicSamplingContextFromSpan(span)
      }

      observer.subscribe({
        complete: () => {
          span?.end()
        },
      })
    })

    return observer
  })

  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      sentryTrace,
      new SentryLink({
        setTransaction: false,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeError: true,
        },
      }),
      new GraphQLWsLink(wsClient),
    ]),
    connectToDevTools: true,
    cache: new InMemoryCache(cachePolicy),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
