import { ELabelProviderType, ENumericFormat, LabelProviderBase2D, formatNumber } from 'scichart'

enum ETradeChartLabelFormat {
  // Apr 25 ... 04:01:45 ... 02:02:30
  Seconds = 'Seconds',
  // Apr 25 ... 01:34 ... 02:24
  Minutes = 'Minutes',
  // Apr ... 08 ... 13
  Days = 'Days',
  // 2020 ... Jan ... Feb
  Months = 'Months',
}
const ONE_HOUR = 60 * 60
const FIVE_DAYS = 60 * 60 * 24 * 5
const FIFTY_DAYS = 60 * 60 * 24 * 50

export class LocalDateLabelProvider extends LabelProviderBase2D {
  type: string = ELabelProviderType.SmartDate
  prevPrevValue?: number
  prevValue?: number
  firstLabel = false
  format?: ETradeChartLabelFormat
  showWiderDateOnFirstLabel = true

  constructor() {
    super()
    // this.numericFormat = ENumericFormat.Date_HHMMSS
    this.firstLabel = true
    this.textVariesForSameTick = true

    this.formatLabelProperty = function (dataValue) {
      let _a, _b
      return this.applyFormat(
        formatNumber(
          dataValue,
          (_a = this.cursorNumericFormat) !== null && _a !== void 0 ? _a : this.numericFormat,
          (_b = this.cursorPrecision) !== null && _b !== void 0 ? _b : this.precision,
        ),
      )
    }
    this.formatLabelProperty = this.doFormat
  }

  onBeginAxisDraw(): void {
    //
  }

  getLabels(majorTicks: any) {
    let first = majorTicks[0]
    const ticksNumber = majorTicks.length
    let last = majorTicks[ticksNumber - 1]
    // Only convert the values we need
    if (this.parentAxis.isCategoryAxis) {
      const categoryCoordCalc: any = this.parentAxis.getCurrentCoordinateCalculator()
      first = categoryCoordCalc.transformIndexToData(first)
      last = categoryCoordCalc.transformIndexToData(last)
    }
    this.prevPrevValue = undefined
    this.prevValue = undefined
    const timeRange = last - first
    this.firstLabel = true
    this.format = this.getTradeChartLabelFormat(timeRange, ticksNumber)
    const labels = super.getLabels.call(this, majorTicks)
    this.format = undefined

    let currentValue = ''
    for (let i = 0; i < labels.length; i++) {
      if (labels[i] === currentValue) {
        labels[i] = ''
      } else {
        currentValue = labels[i]
      }
    }
    return labels
  }

  doFormat(dataValue: number) {
    let _a, _b
    if (this.format) {
      const text = this.formatTradeChartLabel(this.format, dataValue, this.prevValue, this.prevPrevValue)
      this.prevPrevValue = this.prevValue
      this.prevValue = dataValue
      return text
    } else {
      return this.applyFormat(
        formatNumber(
          dataValue,
          (_a = this.cursorNumericFormat) !== null && _a !== void 0 ? _a : this.numericFormat,
          (_b = this.cursorPrecision) !== null && _b !== void 0 ? _b : this.precision,
        ),
      )
    }
  }

  getTradeChartLabelFormat(timeRange: number, ticksNumber: number) {
    if (timeRange <= ONE_HOUR) {
      return ETradeChartLabelFormat.Seconds
    } else if (timeRange <= FIVE_DAYS) {
      return ETradeChartLabelFormat.Minutes
    } else if (timeRange <= FIFTY_DAYS) {
      return ETradeChartLabelFormat.Days
    } else {
      return ETradeChartLabelFormat.Months
    }
  }

  formatTradeChartLabel(
    tradeChartLabelFormat: string,
    value: number,
    prevValue?: number,
    prevPrevValue?: number,
  ): string {
    const showWider = !this.firstLabel || this.showWiderDateOnFirstLabel
    this.firstLabel = false
    if (tradeChartLabelFormat === ETradeChartLabelFormat.Seconds) {
      const newDate =
        prevValue === undefined ||
        formatUnixDateToHumanStringMMMDD(value) !== formatUnixDateToHumanStringMMMDD(prevValue)
      if (newDate && showWider) {
        return formatUnixDateToHumanStringMMMDD(value)
      } else {
        return formatUnixDateToHumanStringHHMMSS(value)
      }
    } else if (tradeChartLabelFormat === ETradeChartLabelFormat.Minutes) {
      const newDate =
        prevValue === undefined ||
        formatUnixDateToHumanStringMMMDD(value) !== formatUnixDateToHumanStringMMMDD(prevValue)
      if (newDate && showWider) {
        return formatUnixDateToHumanStringMMMDD(value)
      } else {
        return formatUnixDateToHumanStringHHMM(value)
      }
    } else if (tradeChartLabelFormat === ETradeChartLabelFormat.Days) {
      return formatUnixDateToHumanStringMMMDD(value)
      // const newMonth =
      //   prevValue === undefined || formatUnixDateToHumanStringMMM(value) !== formatUnixDateToHumanStringMMM(prevValue)
      // if (newMonth && showWider) {
      //   return formatUnixDateToHumanStringMMM(value)
      // } else {
      //   return formatUnixDateToHumanStringDD(value)
      // }
    } else if (tradeChartLabelFormat === ETradeChartLabelFormat.Months) {
      const newYear =
        prevValue === undefined || formatUnixDateToHumanStringYYYY(value) !== formatUnixDateToHumanStringYYYY(prevValue)
      if (newYear && showWider) {
        return formatUnixDateToHumanStringYYYY(value)
      }
      // If previous label was year, display month label
      if (prevValue === undefined) return 'undefined'
      const prevPrevNewYear =
        prevPrevValue === undefined ||
        formatUnixDateToHumanStringYYYY(prevValue) !== formatUnixDateToHumanStringYYYY(prevPrevValue)
      const newMonth =
        prevPrevNewYear || formatUnixDateToHumanStringMMM(value) !== formatUnixDateToHumanStringMMM(prevValue)
      if (newMonth) {
        return formatUnixDateToHumanStringMMM(value)
      }
      return formatUnixDateToHumanStringDD(value)
    }
    return formatNumber(value, ENumericFormat.Date_DDMMYYYY, 0)
  }
}

const formatUnixDateToHumanString = function (unixTimestamp: number, locale: string) {
  if (locale === void 0) {
    locale = 'en-US'
  }
  const res = new Date(unixTimestamp * 1000).toLocaleDateString(locale, {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
  })
  if (res === 'Invalid Date') {
    return ''
  }
  return res
}

const formatUnixDateToHumanStringDDMMYY = (unixTimestamp: number) => {
  const res = new Date(unixTimestamp * 1000).toLocaleDateString('en-GB', {
    //timezone: 'utc',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })
  if (res === 'Invalid Date') {
    return ''
  }
  return res
}

const formatUnixDateToHumanStringDDMMHHMM = (unixTimestamp: number) => {
  const ddmm = formatUnixDateToHumanStringDDMM(unixTimestamp)
  const hhmm = formatUnixDateToHumanStringHHMM(unixTimestamp)
  return ''.concat(ddmm, ' ').concat(hhmm)
}

const formatUnixDateToHumanStringDDMM = (unixTimestamp: number) => {
  const res = new Date(unixTimestamp * 1000).toLocaleDateString('en-GB', {
    //timezone: 'utc',
    day: 'numeric',
    month: 'numeric',
  })
  if (res === 'Invalid Date') {
    return ''
  }
  return res
}

const formatUnixDateToHumanStringHHMMSS = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return ''
  }
  const hoursString = hours <= 9 ? '0'.concat(hours.toString()) : hours.toString(10)
  const minutesString = minutes <= 9 ? '0'.concat(minutes.toString()) : minutes.toString(10)
  const secondsString = seconds <= 9 ? '0'.concat(seconds.toString()) : seconds.toString(10)
  return ''.concat(hoursString, ':').concat(minutesString, ':').concat(secondsString)
}

const formatUnixDateToHumanStringHHMM = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  if (isNaN(hours) || isNaN(minutes)) {
    return ''
  }
  const hoursString = hours <= 9 ? '0'.concat(hours.toString()) : hours.toString(10)
  const minutesString = minutes <= 9 ? '0'.concat(minutes.toString()) : minutes.toString(10)
  return ''.concat(hoursString, ':').concat(minutesString)
}

const formatUnixDateToHumanStringMMMDD = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000)
  const month = date.getMonth()
  const day = date.getDate()
  if (isNaN(month) || isNaN(day)) {
    return ''
  }
  return ''.concat(day.toString(), '. ').concat(getMonthString(month))
}

const formatUnixDateToHumanStringMMM = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000)
  const month = date.getMonth()
  if (isNaN(month)) {
    return ''
  }
  return getMonthString(month)
}

const formatUnixDateToHumanStringDD = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000)
  const day = date.getDate()
  if (isNaN(day)) {
    return ''
  }
  const strDay = day.toString()
  if (strDay.length === 2) {
    return strDay
  } else if (strDay.length === 1) {
    return '0'.concat(strDay)
  } else {
    return ''
  }
}

const formatUnixDateToHumanStringYYYY = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000)
  const year = date.getFullYear()
  if (isNaN(year)) {
    return ''
  }
  return year.toString(10)
}

const getMonthString = function (month: number) {
  switch (month) {
    case 0:
      return 'Jan'
    case 1:
      return 'Feb'
    case 2:
      return 'Mar'
    case 3:
      return 'Apr'
    case 4:
      return 'May'
    case 5:
      return 'Jun'
    case 6:
      return 'Jul'
    case 7:
      return 'Aug'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dec'
    default:
      throw Error('Not correct month')
  }
}
