import * as Sentry from '@sentry/react'
import {
  browserProfilingIntegration,
  moduleMetadataIntegration,
  reactRouterV6BrowserTracingIntegration,
  metrics,
  replayCanvasIntegration,
} from '@sentry/react'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { SentryContext } from './SentryContext'
import { contextLinesIntegration, extraErrorDataIntegration, captureConsoleIntegration } from '@sentry/integrations'

const sentryReplayIntegration = Sentry.replayIntegration({
  maskAllText: false,
  blockAllMedia: false,
  stickySession: false,
})

export const InitialiseSentry = () => {
  const environment =
    process.env.REACT_APP_ENV === 'development' ? (process.env.REACT_APP_SENTRY_ENV ?? 'development') : window.location.hostname.split('.')[0]
  Sentry.init({
    dsn:
      process.env.REACT_APP_ENV === 'production'
        ? 'https://510fa78e8c85e511077d82022a4995ec@sentry.fpinformatics.net/6'
        : 'https://d0d0233b3a91eb4e4a99ee6366e78106@sentry.fpinformatics.net/3',
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      browserProfilingIntegration(),
      contextLinesIntegration(),
      extraErrorDataIntegration(),
      captureConsoleIntegration({ levels: ['warn', 'error', 'assert'] }),
      moduleMetadataIntegration(),
      metrics.metricsAggregatorIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      sentryReplayIntegration,
      replayCanvasIntegration(),
    ],
    // Performance Monitoring
    enableTracing: true,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^http(s?):\/\/localhost(:\d+)?\//,
      /^https:\/\/.+\.fpinformatics\.net\//,
      /^https:\/\/(?!auth)(.+)\.fpresearch\.net\//,
    ],
    environment,
    release: 'vitaldetect-ui@' + process.env.REACT_APP_VERSION,
    autoSessionTracking: true,
    replaysOnErrorSampleRate: 1.0,
    sampleRate: 1.0,
    profilesSampleRate: 0.0,
    // debug: true,
  })
}

export const SentryContextProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [sentry, setSentry] = useState<{ replayIntegration: Sentry.Replay }>()

  useEffect(() => {
    setSentry({ replayIntegration: sentryReplayIntegration })
  }, [])

  return <SentryContext.Provider value={sentry}>{children}</SentryContext.Provider>
}
