import { useQuery, gql } from '@apollo/client'
import { Ward, GraphQLResult } from '../types'

export const QUERY_WARD = gql`
  query QueryWard($wardId: ID!) {
    result: ward(id: $wardId) {
      id
      name
      activePatientIds
    }
  }
`

export const useWard = (wardId: number) => {
  const { loading, data, error } = useQuery<GraphQLResult<Ward>>(QUERY_WARD, { variables: { wardId } })

  const result = data?.result
  return { loading, data: result, error }
}
