import React, { FC, ReactElement, useEffect, useState } from 'react'
import { InstanceSettingContext } from '.'

import { Info } from '../components'
import { InstanceSetting } from '../types'

export const InstanceSettingProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [instanceSetting, setInstanceSetting] = useState<InstanceSetting>()
  const endpoint = window.location.hostname === 'localhost' ? 'localhost:5001' : 'api.' + window.location.host

  useEffect(() => {
    fetch(`https://${endpoint}/v1/setting/instance`, { cache: 'no-cache' })
      .then((response) => {
        return response.json()
      })
      .then((value) => setInstanceSetting({ apiEndpoint: endpoint, ...value }))
  }, [])

  if (!instanceSetting) return <Info text="Loading ... " />
  return <InstanceSettingContext.Provider value={instanceSetting}>{children}</InstanceSettingContext.Provider>
}
