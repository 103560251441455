type Theme = {
  '--sidebar-color': string
  '--pri-ft-color': string
  '--sec-ft-color': string
  '--pri-bg-color': string
  '--sec-bg-color': string
  '--border-color': string
  '--button-active-color': string
  '--toolbar-button-color': string
  '--patient-row-hover': string
  '--dropdown-hover': string
  '--chart-bg-color': string
  '--chart-bar-color': string
  '--chart-light-fill': string
  '--navigator-mask-color': string
  '--button-hover-color': string
  '--tile-color': string
  '--tooltip-bg-color': string
  '--tooltip-color': string
  '--ews-white': string
}

export const themeLight: Theme = {
  '--sidebar-color': '#27293e',
  '--pri-ft-color': '#000000',
  '--pri-bg-color': '#eeeeee',
  '--sec-ft-color': '#000000',
  '--sec-bg-color': '#ffffff',
  '--border-color': '#707070',
  '--chart-bg-color': '#ffffff',
  '--chart-bar-color': '#ffffff',
  '--chart-light-fill': '#f0f0f0',
  '--button-active-color': '#00bbff',
  '--toolbar-button-color': '#bbbbbb',
  '--patient-row-hover': '#d3f0fd',
  '--dropdown-hover': '#d3f0fd',
  '--navigator-mask-color': 'rgba(255, 255, 255, 0.85)',
  '--tooltip-bg-color': 'rgba(255, 255, 255, 0.1)',
  '--button-hover-color': '#e6e6e6',
  '--tile-color': '#ffffff',
  '--tooltip-color': '#ffffff',
  '--ews-white': '#e0e0e0',
}

export const themeDark: Theme = {
  '--sidebar-color': '#27293e',
  '--pri-ft-color': '#ffffff',
  '--pri-bg-color': '#000000',
  '--sec-ft-color': '#ffffff',
  '--sec-bg-color': '#727272',
  '--border-color': '#545454',
  '--chart-bg-color': '#333333',
  '--chart-bar-color': '#444444',
  '--chart-light-fill': '#ffffff',
  '--button-active-color': '#00bbff',
  '--toolbar-button-color': '#444444',
  '--patient-row-hover': '#00bbff',
  '--dropdown-hover': '#00bbff',
  '--navigator-mask-color': 'rgba(80, 80, 80, 0.8)',
  '--button-hover-color': '#707070',
  '--tile-color': '#444',
  '--tooltip-bg-color': 'rgba(51, 51, 51, 0.1)',
  '--tooltip-color': '#444444',
  '--ews-white': '#eae9e7',
}

const light = 'light'
const dark = 'dark'
const themeStorageKey = 'key_active-theme'

export let activeThemeName: string | null = localStorage.getItem(themeStorageKey)
let currentTheme: any

export const setTheme = (theme: Theme = themeLight) => {
  const root = document.documentElement
  for (const key in theme) {
    root.style.setProperty(key, theme[key as keyof Theme])
  }
  currentTheme = theme
}

export const initializeTheme = () => {
  if (!activeThemeName) {
    localStorage.setItem('activeTheme', dark)
    activeThemeName = dark
  }

  currentTheme = activeThemeName === light ? themeLight : themeDark
  setTheme(currentTheme)
}

export const switchTheme = () => {
  //   setTheme(currentTheme === themeLight ? themeDark : themeLight)
  activeThemeName = activeThemeName === dark ? light : dark
  localStorage.setItem(themeStorageKey, activeThemeName)
}

export const getThemeName = (): string => {
  return activeThemeName ?? ''
}

const bodyStyle = getComputedStyle(document.body)

export const getColor = (name: string): string => {
  return bodyStyle.getPropertyValue(name)
}
