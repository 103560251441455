import { inactiveSymbol } from '../Constants'
import { MetricSettings } from '../types'

export const toMetricDisplayText = (value: string | undefined, settings?: MetricSettings): string => {
  if (value === undefined) return inactiveSymbol
  if (!settings) {
    const d = getDecimalPlaces(value)
    if (3 < d) {
      const v = value.substring(0, value.length - d + 3)
      return v
    }

    return value
  }

  const decimalPlaces = settings?.roundUpDigits !== undefined ? settings.roundUpDigits : 0
  const metricString = toRoundedMetric(value, decimalPlaces)
  if (metricString === undefined) {
    return inactiveSymbol
  }

  const data = Number(metricString)
  const { min, categories } = settings
  if (categories) {
    return categories[data - (min ?? 0)]
  }
  return metricString.toString()
}

export const toRoundedMetric = (value: string | undefined, decimalPlaces: number): string | undefined => {
  if (value === undefined) return undefined
  const data = parseFloat(value)
  if (isNaN(data)) return undefined

  return data.toFixed(decimalPlaces)
}

export const getDecimalPlaces = (value: string) => {
  const segments = value.split('.')
  if (1 < segments.length) {
    return segments[segments.length - 1].length
  }
  return 0
}

export const toMetricColor = (value: string | number | undefined, settings: MetricSettings) => {
  if (!settings) return
  const { min, max, colors, zones } = settings
  if (!zones || !colors) return
  const data = typeof value === 'string' ? Math.round(parseFloat(value)) : value
  if (data === undefined || isNaN(data)) {
    return
  }
  if ((min !== undefined && data < min) || (max !== undefined && data > max)) {
    return
  }
  if (!colors?.length) {
    return
  }
  let index = -1
  zones?.forEach((zone, i) => {
    if (zone <= data) index = i
  })
  if (index >= colors.length) return
  return colors[index]
}
