import { differenceInSeconds } from 'date-fns'
import { useModulesSettings } from '../hooks'
import { DateRangeTimestamp, PatientModuleSettings } from '../types'

class TimeRangeService {
  private timeScale: number

  constructor(timeScale: number) {
    this.timeScale = timeScale
  }

  public fetchData(id: string, dataRange: DateRangeTimestamp, delaySeconds: number): Promise<number[][]> {
    return new Promise<number[][]>((resolve, reject) => {
      const data: number[][] = []
      if (!dataRange.start) {
        resolve(data)
        return
      }

      const end = dataRange.end ? dataRange.end : new Date().getTime()
      const range = differenceInSeconds(dataRange.start, end)
      for (let i = range - delaySeconds; i <= -delaySeconds; i++) {
        const point = [(end + i * 1000) / this.timeScale, 0]
        data.push(point)
      }
      resolve(data)
    })
  }

  public subscribe(id: string, callback: (data: number[]) => void) {
    const timer = setInterval(() => callback(this.getDataPoint(id)), 1000)
    return () => clearInterval(timer)
  }

  getDataPoint(id: string): number[] {
    const { liveStreamDelaySecs } = useModulesSettings()['patient'] as PatientModuleSettings
    // delay by 2s to allow the line to be drawn before coming into view
    const timestamp = new Date().getTime() / this.timeScale - liveStreamDelaySecs
    // const value = getRandomNumber(id)
    return [timestamp, 0]
  }
}

export const getTimeRangeService = (timeScale: number) => {
  return new TimeRangeService(timeScale ?? 1)
}
