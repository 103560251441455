import { gql, useQuery } from '@apollo/client'
import { Patient, GraphQLResult, PatientResponse } from '../types'
import { parseISO } from 'date-fns'

const QUERY_PATIENT = gql`
  query QueryPatient($patientId: ID!) {
    result: patient(id: $patientId) {
      id
      firstName
      lastName
      reference
    }
  }
`

const QUERY_PATIENT_AND_SESSION_DATE = gql`
  query QueryPatient($patientId: ID!) {
    result: patient(id: $patientId) {
      id
      firstName
      lastName
      reference
      firstSessionDate
    }
  }
`

export const usePatient = (patientId: string, includeSessionDate?: boolean): Patient => {
  const query = includeSessionDate ? QUERY_PATIENT_AND_SESSION_DATE : QUERY_PATIENT
  const { loading, data, error } = useQuery<GraphQLResult<PatientResponse>>(query, {
    variables: { patientId },
  })
  if (!loading && !error && data && data.result) {
    const firstSessionDate = data.result.firstSessionDate ? parseISO(data.result.firstSessionDate) : undefined
    return { ...data.result, firstSessionDate }
  }
  if (error) {
    console.warn(error)
  }
  return {
    id: patientId,
    firstName: loading ? 'loading...' : 'error!!!',
    lastName: '',
  }
}
