import { gql, useQuery } from '@apollo/client'
import { useBatchQuery } from './apolloHelper'
import { Device, DeviceStatus, GraphQLResult } from '../types'

const QUERY_DEVICE = gql`
  query QueryDevice($deviceId: String!) {
    result: device(id: $deviceId) {
      id
      mac
      name
      type
      serialNumber
    }
  }
`

export const useDevice = (deviceId: string): Device => {
  const { loading, data, error } = useQuery<GraphQLResult<Device>>(QUERY_DEVICE, {
    variables: { deviceId },
  })
  if (!loading && !error && data && data.result) {
    return data.result
  }
  if (error) {
    console.warn(error)
  }
  return {
    id: deviceId,
    type: 'unknown',
    mac: '',
    name: loading ? 'loading...' : 'error!!!',
    serialNumber: '',
  }
}

const QUERY_DEVICE_STATUS = gql`
  query QueryDevice($deviceId: String!) {
    result: device(id: $deviceId) {
      id
      status {
        id
        isConnected
      }
    }
  }
`

export const useDeviceStatus = (deviceId?: string) => {
  if (!deviceId) return { loading: false, error: false, data: undefined }
  const { loading, data, error } = useQuery<GraphQLResult<Device>>(QUERY_DEVICE_STATUS, {
    variables: { deviceId },
    fetchPolicy: 'cache-and-network',
  })

  return { loading, error, data: data?.result?.status }
}

export const useDevices = (
  deviceIds: string[],
  isChanged?: (oldVal: Device, newVal: Device) => boolean,
): (Device | null)[] => {
  const params = deviceIds.map((deviceId) => ({ deviceId }))
  const result = useBatchQuery<GraphQLResult<Device>>(
    QUERY_DEVICE,
    params,
    (param) => '' + param.deviceId,
    isChanged
      ? (oldVal, newVal) => {
        return oldVal.result && newVal.result ? isChanged(oldVal.result, newVal.result) : true
      }
      : undefined,
  )
  return result.map((item) => (item ? item.result : null))
}
