import { Options } from 'highcharts'
import { getColor } from '../../../themes'
import { GaugeChartOptions } from '../../../types'
import json from '../../../assets/color.json'

const color = json.color.base

const toHex = (key: string) => {
  switch (key) {
    case 'blue':
      return color.blue[40].value
    case 'red':
      return color.spec.red.value
    case 'orange':
      return color.accent.orange.value
    case 'yellow':
      return color.yellow[50].value
    case 'white':
    default:
      return getColor('--chart-light-fill')
  }
}

const getTickPositions = (min: number, max: number) => {
  const range = max - min
  const count = (range / 5) % 10 === 0 ? 5 : 4
  const tickPositions = [min]
  for (let i = 1; i <= count; i++) {
    tickPositions.push(Math.round(min + (i * range) / count))
  }
  return tickPositions
}

function onLoad(chart: any, shrinkDataLabel: boolean, unit?: string, fontColor?: string) {
  if (!unit) return

  const point: any = chart.series[0].points[0]
  const y = point.plotY + point.dataLabel.height + (shrinkDataLabel ? 5 : 2)
  // const pane = chart.pane[0].group.getBBox()
  // const y = pane.height + pane.y
  const fontSize = unit.length === 1 ? '12px' : unit.length < 4 ? '10px' : '9px'
  const label = chart.renderer.text(unit, point.plotX, y).attr({ fill: fontColor, zIndex: 5 }).css({ fontSize }).add()
  const width = label.getBBox().width
  label.attr({ translateX: -width / 2 })
}

function onRedraw(chart: any) {
  const point: any = chart.series[0].points[0]
  const width = point.dataLabel.width
  point.dataLabel.attr({ translateX: point.plotX - width / 2 })
}

export const getGaugeChartConfig = ({
  defaultValue = 0,
  backgroundColor,
  decimalPlaces,
  fontColor,
  setting,
}: GaugeChartOptions) => {
  const showValue = setting.hideValue !== true
  const min = setting?.min !== undefined ? setting.min : 0
  const max = setting?.max ?? 100
  const shrinkDataLabel = 1 < decimalPlaces
  const fontSize = shrinkDataLabel ? '20px' : '23px'
  const yOffset = shrinkDataLabel ? -15 : -18
  let plotBand = []
  const thickness = 8

  if (setting && setting.colors && setting.zones) {
    for (let i = 0; i < setting.colors.length; i++) {
      plotBand.push({
        from: setting.zones[i],
        to: setting.zones[i + 1],
        color: toHex(setting.colors[i]),
        thickness: thickness,
      })
    }
  } else {
    plotBand = [
      {
        from: 0,
        to: 20,
        color: '#888888',
        thickness: thickness,
      },
      // {
      //   from: 20,
      //   to: 75,
      //   color: '#86b841',
      //   thickness: thickness,
      // },
      // {
      //   from: 75,
      //   to: 100,
      //   color: '#e78534',
      //   thickness: thickness,
      // },
    ]
  }

  const options: Options = {
    chart: {
      type: 'gauge',
      backgroundColor: backgroundColor,
      borderWidth: 0,
      plotBackgroundColor: undefined,
      plotBackgroundImage: undefined,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '100%',
      spacing: [0, 0, 0, 0],
      animation: false,
      events: {
        load: function () {
          onLoad(this, shrinkDataLabel, setting.unit, fontColor)
        },
        redraw: function () {
          onRedraw(this)
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      enabled: false,
    },
    pane: {
      startAngle: -145,
      endAngle: 145,
      background: [
        {
          backgroundColor: backgroundColor,
          borderWidth: 0,
        },
      ],
      center: ['50%', '50%'],
      size: '65%',
    },
    yAxis: {
      min: min,
      max: max,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: backgroundColor,
      tickLength: 20,
      tickWidth: 0,
      lineColor: undefined,
      minorTickInterval: null,
      tickPositions: getTickPositions(min, max),
      labels: {
        enabled: showValue,
        distance: 10,
        style: {
          color: fontColor,
          fontSize: '10px',
          fontFamily: 'arial',
        },
      },
      plotBands: plotBand,
    },
    series: [
      {
        type: 'gauge',
        name: 'Value',
        data: [defaultValue],
        dataLabels: {
          enabled: showValue,
          formatter: function () {
            return String(this.y).padStart(2, '0')
          },
          allowOverlap: true,
          borderWidth: 0,
          color: fontColor,
          align: 'left',
          style: {
            fontSize,
            fontFamily: 'arial',
          },
          zIndex: 10,
        },
        dial: {
          radius: '85%',
          backgroundColor: fontColor,
          baseWidth: 4,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: backgroundColor,
          radius: showValue ? 24 : 15,
          borderColor: getColor('--border-color'),
          borderWidth: 1,
        },
      },
    ],
    plotOptions: {
      gauge: {
        wrap: false,
      },
      series: {
        dataLabels: {
          y: yOffset,
        },
      },
    },
    credits: {
      enabled: false,
    },
  }
  return options
}
