import { Metric } from '../types'

const activeMetricsKey = '_active-metrics'
const patientStatusKey = '_patient-status'

class UIStateService {
  public saveExpanded(id: string, expandedItems: Metric[]) {
    localStorage.setItem(id + activeMetricsKey, JSON.stringify(expandedItems.map((x) => x.name)))
  }

  public loadExpanded(id: string): string[] {
    const result = localStorage.getItem(id + activeMetricsKey)
    return result ? JSON.parse(result) : []
  }

  public savePatientStatus = (key: string, value: number) => {
    localStorage.setItem(key + patientStatusKey, '' + value)
  }

  public loadPatientStatus = (key: string) => {
    return parseFloat(localStorage.getItem(key + patientStatusKey) ?? '0')
  }

  public saveBoolean = (key: string, value: boolean) => {
    localStorage.setItem(key, value ? '1' : '0')
  }

  public isTrueOrNull = (key: string) => {
    const result = localStorage.getItem(key)
    return result === null || result === '1'
  }
}

const instance = new UIStateService()

export { instance as uiStateService }

export const saveExpandedMap = (id: string, expandedMap: Record<string, 1>) => {
  localStorage.setItem(id + activeMetricsKey, JSON.stringify(Object.keys(expandedMap)))
}

export const loadExpandedMap = (id: string): Record<string, 1> => {
  const result = localStorage.getItem(id + activeMetricsKey)
  if (!result) {
    return {}
  }
  const expanded = JSON.parse(result) as string[]
  const record: Record<string, 1> = {}
  expanded.forEach((value) => (record[value] = 1))
  return record
}
