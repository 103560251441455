import { Options, RangeSelectorOptions } from 'highcharts'
import { getVar } from '../../../layout'
import { StockChartOptions } from '../../../types'

export const getTimeAxisConfig = ({ height, name: name, backgroundColor, fontColor }: StockChartOptions) => {
  const stockChartConfig: Options = {
    chart: {
      height: height ?? 200,
      spacing: [0, 0, 0, 0],
      margin: [30, 0, 0, getVar('chart-left-margin')],
      backgroundColor: undefined,
    },
    accessibility: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    tooltip: {
      enabled: true,
      xDateFormat: '%Y-%m-%d',
      shared: true,
    },
    title: {
      text: undefined,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        hour: '%l:%M %P',
      },
      labels: {
        style: { color: fontColor, fontSize: '10px' },
      },
      opposite: true,
      minPadding: 0,
      maxPadding: 0,
      lineWidth: 0,
      tickWidth: 0,
      events: {},
    },
    yAxis: {
      height: height,
      title: {
        text: name,
        style: {
          color: fontColor,
        },
      },
      labels: {
        style: { color: fontColor },
      },
      gridLineColor: '#000', // Todo*
      gridLineDashStyle: 'Dot',
      gridLineWidth: 1,
      opposite: false,
      min: 10, // Todo*
      max: 105,
      endOnTick: false,
    },
    series: [
      {
        type: 'line',
      },
    ],
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: 1,
        color: fontColor,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  }
  return stockChartConfig
}
