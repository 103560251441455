import { useCallback, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useReleasePatient } from '../../hooks/useReleasePatient'

export const DeletePatientModal = ({ patientId }: { patientId: string }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <div className="v-iconButton" onClick={openModal}>
        Delete&nbsp;
        <span className="fontIcon">trash</span>
      </div>
      <Dialog className="v-modal" open={isOpen} onClose={closeModal}>
        <DialogTitle>Delete Patient</DialogTitle>
        <span className="fontIcon v-modal-closeButton" onClick={closeModal}>
          close
        </span>
        <DeletePatientView patientId={patientId} onClose={closeModal} />
      </Dialog>
    </>
  )
}

export const DeletePatientView = ({ patientId, onClose }: { patientId: string; onClose: () => void }) => {
  const { wardId } = useParams()
  const wardIdValue = wardId ? parseInt(wardId) : -1
  const { releasePatient, loading, error } = useReleasePatient(patientId, wardIdValue)

  const handleClick = useCallback(() => {
    releasePatient()
  }, [])

  return (
    <>
      <div className="v-modal-content">
        {error ? <div className="v-modal-warning">{error}</div> : null}
        <DialogContent>
          <Typography>Are you sure you want to delete this patient?</Typography>
        </DialogContent>
      </div>
      <br />
      <div className="v-modal-footer">
        <DialogActions>
          <Button variant="outlined" disabled={loading} disableRipple onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={loading} autoFocus disableRipple onClick={handleClick}>
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </div>
    </>
  )
}
