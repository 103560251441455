import { IAggregator } from './IAggregator'

class AverageAggregator implements IAggregator {
  private sum = 0
  private count = 0
  private interval = 0
  private recentData: number[][] = []

  constructor(interval: number) {
    this.interval = interval
  }

  public addData(data: number[]): number[][] {
    if (!this.interval) return []
    const dataTime = data[0]
    const value = data[1]
    if (isNaN(value)) return [[dataTime, NaN]]
    const start = dataTime - this.interval

    let itemsToRemove = 0
    for (let i = 0; i < this.recentData.length; i++) {
      const d = this.recentData[i]
      if (d[0] < start) {
        itemsToRemove = i + 1
        this.sum -= d[1]
        this.count--
      } else {
        break
      }
    }

    this.recentData.splice(0, itemsToRemove)

    this.recentData.push(data)
    this.sum += value
    this.count++

    return [[dataTime, this.getValue()]]
  }

  public getValue(): number {
    if (this.count <= 0) return 0
    return this.sum / this.count
  }
}

export const createAverageAggregator = (interval: number) => {
  return new AverageAggregator(interval)
}
