import { gql, useQuery, makeVar, ApolloError } from '@apollo/client'
import { DeviceGroup, DeviceSettings, MetricSettings, ModulesSettings, PatientModuleSettings } from '../types'
import { startOfHour, subHours } from 'date-fns'

export type OrgSettings = {
  modules: ModulesSettings
  metrics: Record<string, MetricSettings>
  deviceGroups: DeviceGroup[]
  devices: Record<string, DeviceSettings>
}

export type LoginContext = {
  defaultWard: number
  selectedWard?: number
}

const empty = {}
const cachedOrgConfig = makeVar<OrgSettings>(empty as OrgSettings)
const cachedLoginContext = makeVar<LoginContext>(empty as LoginContext)

type InitialData = {
  orgSettings: string
  loginContext: LoginContext
}

const QUERY_INITIAL_DATA = gql`
  query QueryOrgSettings {
    orgSettings
  }
`

export const loadInitialData = (): { ready: boolean; error?: ApolloError } => {
  const { loading, data, error } = useQuery<InitialData>(QUERY_INITIAL_DATA)

  if (loading) {
    return { ready: false }
  }

  if (data) {
    cachedOrgConfig(JSON.parse(data.orgSettings))
    cachedLoginContext({
      defaultWard: 0,
    })
    return { ready: true }
  } else {
    console.warn(error || 'Unknown graphQL query issue when loading OrgSettings')
  }

  return { ready: false, error }
}

export const useModulesSettings = (): ModulesSettings => {
  return cachedOrgConfig().modules
}

export const useMetricSettings = (): Record<string, MetricSettings> => {
  return cachedOrgConfig().metrics
}

export const useDeviceGroupSettings = (): DeviceGroup[] => {
  return cachedOrgConfig().deviceGroups
}

export const useDeviceSettings = (): Record<string, DeviceSettings> => {
  return cachedOrgConfig().devices
}

export const useLoginContext = (): LoginContext => {
  return cachedLoginContext()
}

export type DisplayContext = {
  start: Date
  refresh: number
}

const displayContext = makeVar<DisplayContext | null>(null)

export const useDisplayContext = (): DisplayContext => {
  const now = new Date()
  const nowSec = now.getTime()
  let context = displayContext()
  if (!context || nowSec > context.refresh + 3600000) {
    const refresh = nowSec
    const patientModuleSettings = useModulesSettings()['patient'] as PatientModuleSettings
    const start = subHours(startOfHour(now), patientModuleSettings.dataRangeHours)
    context = { start, refresh }
    displayContext(context)
  }
  return context
}
