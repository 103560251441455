import React, { FC, useCallback, useState } from 'react'
import { uiStateService, updatePatientStatus } from '../../services'
import {
  useMetricSettings,
  useActivePatientList,
  useWard,
  useFirebaseRoutesAnalytics,
  usePatientFilter,
} from '../../hooks'
import { useParams } from 'react-router-dom'
import { ActivePatientInfo, PatientViewModel } from '../../types'
import { PatientInfoContainer } from '.'
import { useApolloClient } from '@apollo/client'
import { patientComparer } from '../../utils'

export const Dashboard = () => {
  const { wardId } = useParams()
  const ward = parseInt(wardId ?? '0')
  const { data } = useWard(ward)
  const activePatientIds = data ? data.activePatientIds : []
  useFirebaseRoutesAnalytics()

  return (
    <div className="v-dashboard">
      <div className="v-dashboard-header">
        <div className="v-dashboard-title">Clinical Risk</div>
      </div>
      <EwsContainer patientIds={activePatientIds} wardId={ward} />
    </div>
  )
}

const EwsContainer: FC<{ patientIds: string[]; wardId: number }> = ({ patientIds, wardId }) => {
  const patients = usePatientFilter(toModel(useActivePatientList(patientIds))).sort(patientComparer)
  const settings = useMetricSettings()['ews']

  const data =
    settings?.colors?.map((color, index) => {
      return {
        name: color,
        index,
        patients: patients.filter((p) => pick(p.ews, index, settings?.zones)),
        collapsible: index === 0,
      }
    }) ?? []

  return (
    <div className="v-dashboard-content">
      {data.map((d) => (
        <EwsGroup
          name={d.name}
          zone={d.index}
          key={d.index}
          patients={d.patients}
          wardId={wardId}
          collapsible={d.collapsible}
        />
      ))}
    </div>
  )
}

const EwsGroup: FC<{
  name: string
  patients: PatientViewModel[]
  zone: number
  wardId: number
  collapsible: boolean
}> = ({ name, patients, zone, wardId, collapsible }) => {
  const storageKey = `ewsGroup-${name}-expanded`
  const initialState = collapsible ? uiStateService.isTrueOrNull(storageKey) : false
  const [isExpanded, setExpanded] = useState<boolean>(initialState)
  const client = useApolloClient()

  const setPatientStatus = (patientId: string, newStatus: number) => {
    updatePatientStatus(client.cache, patientId, newStatus)
  }

  const handleClick = collapsible
    ? useCallback(() => {
      setExpanded(!isExpanded)
      uiStateService.saveBoolean(storageKey, !isExpanded)
    }, [isExpanded])
    : undefined

  const getClass = useCallback(() => {
    let result = `v-ewsGroup v-ewsGroup--${name}`
    if (isExpanded) result += ' v-ewsGroup--expanded'
    return result
  }, [isExpanded])

  return (
    <div className={getClass()} key={name}>
      <div className={`v-ewsGroup-header u-noSelect ${collapsible ? 'u-pointer' : ''}`} onClick={handleClick}>
        <div className="v-ewsGroup-title">{name}</div>
        {collapsible ? (
          <span className="v-ewsGroup-icon fontIcon">{isExpanded ? 'caretRight' : 'caretLeft'}</span>
        ) : null}
      </div>
      <div className={`v-ewsGroup-content v-ewsGroup-content--${name}`}>
        {zone === 0 ? (
          isExpanded ? (
            <PatientInfoContainer patients={patients} zone={zone} wardId={wardId} onStatusChange={setPatientStatus} />
          ) : undefined
        ) : (
          <>
            <PatientInfoContainer
              patients={patients.filter((p) => !p.status)}
              zone={zone}
              wardId={wardId}
              onStatusChange={setPatientStatus}
            />
            <PatientInfoContainer
              patients={patients.filter((p) => p.status === 1)}
              zone={zone}
              wardId={wardId}
              alignment="bottom"
              onStatusChange={setPatientStatus}
            />
          </>
        )}
      </div>
    </div>
  )
}

const pick = (value: string | undefined, zoneIndex: number, zones?: number[]) => {
  const data = parseFloat(value ?? '')
  if (isNaN(data)) {
    if (zoneIndex === 0) return true
  }
  if (!zones) return true

  const min = zones[zoneIndex] ?? -1
  const max = zones[zoneIndex + 1] ?? -1

  return min <= data && data < max
}

const toModel = (data: ActivePatientInfo[]): PatientViewModel[] => {
  return data.map((d) => {
    return {
      id: d.id,
      firstName: d.firstName,
      lastName: d.lastName,
      reference: d.reference,
      ews: d.devices.flatMap((x) => x.aggregates).find((a) => a.name === 'ews')?.value,
      devices: d.devices,
      status: d.status ?? 0,
    }
  })
}
