import { addHours } from 'date-fns'
import json from '../assets/color.json'

const color = json.color.base

export const noOp = () => {
  //
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const toCapitalizedPhrase = (value: string): string => {
  if (value.length < 8) return value
  const words = value.replace(/([a-z])([A-Z])/g, '$1-$2').split('-')
  return words.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).join(' ')
}

export const toHexColor = (key: string) => {
  return lookupColor(key).substring(0, 7)
}

const lookupColor = (key: string) => {
  switch (key) {
    case 'blue':
      return color.blue[40].value
    case 'red':
      return color.spec.red.value
    case 'orange':
      return color.accent.orange.value
    case 'yellow':
      return color.yellow[50].value
    case 'white':
    default:
      return '#ffffffff'
  }
}

export const makeModuleLinkUrl = (
  params: Record<string, string | undefined>,
  pageUrl: string,
  urlParams?: string[],
) => {
  if (!urlParams) {
    return pageUrl
  }
  let result = pageUrl
  for (const paramName of urlParams) {
    const val = params[paramName]
    result = result.replaceAll(`:${paramName}`, val || '')
  }
  return result
}

export function roundToHalfHour(date: Date, direction?: string): Date {
  let result = new Date(date)
  const minutes = result.getMinutes()

  const isFirstHalfHour = minutes - 30 < 0
  result.setSeconds(0)
  result.setMilliseconds(0)

  if (direction !== 'up') {
    isFirstHalfHour ? result.setMinutes(0) : result.setMinutes(30)
    return result
  }

  if (isFirstHalfHour) {
    result.setMinutes(30)
  } else {
    result.setMinutes(0)
    result = addHours(result, 1)
  }
  return result
}

export function throttle<T extends (...args: any[]) => void>(mainFunction: T, delay: number) {
  let timerFlag: NodeJS.Timeout | null = null

  return (...args: Parameters<T>) => {
    if (timerFlag === null) {
      mainFunction(...args)
      timerFlag = setTimeout(() => {
        timerFlag = null
      }, delay)
    }
  }
}
