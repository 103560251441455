import { endOfHour, isEqual, isSameHour, startOfHour } from 'date-fns'
import { IAggregator } from './IAggregator'

class HourlyAggregator implements IAggregator {
  private accumulativeSteps = 0
  private recentData: number[][] = []
  private bucket: Date = new Date()

  public addData(data: number[]): number[][] {
    const dataTime = data[0]
    const dataStartHour = startOfHour(dataTime)

    if (isSameHour(dataStartHour, this.bucket)) {
      if (this.recentData.length) {
        const diff = data[1] - this.recentData[this.recentData.length - 1][1]
        if (diff > 0) {
          this.accumulativeSteps += diff
        }
        this.recentData.push(data)
      }
      return []
    }

    this.bucket = dataStartHour

    let result = this.recentData.map((d) => [d[0], this.getValue()])
    if (result.length) {
      const firstTimeValue = result[0][0]
      if (!isEqual(new Date(firstTimeValue), startOfHour(firstTimeValue))) {
        result = [[startOfHour(firstTimeValue).getTime(), this.getValue()], ...result]
      }

      const lastTimeValue = result[result.length - 1][0]
      if (!isEqual(new Date(lastTimeValue), endOfHour(lastTimeValue))) {
        result.push([endOfHour(lastTimeValue).getTime(), this.getValue()])
      }
    }

    this.recentData = [data]
    this.accumulativeSteps = 0
    return result
  }

  public getValue(): number {
    if (!this.recentData.length) return 0
    return this.accumulativeSteps
    // const value = this.recentData[this.recentData.length - 1][1] - this.recentData[0][1]
    // return value > 0 ? value : 0
  }
}

export const createHourlyAggregator = () => {
  return new HourlyAggregator()
}
