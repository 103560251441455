import { CSSProperties, useState, useRef, useEffect } from 'react'
import {
  SciChartSurface,
  NumericAxis,
  XyDataSeries,
  FastLineRenderableSeries,
  RolloverModifier,
  EXyDirection,
  ZoomPanModifier,
  ZoomExtentsModifier,
  CursorModifier,
  generateGuid,
  SciChartOverview,
  EAxisAlignment,
  SciChartJSDarkTheme,
  ENumericFormat,
  DateTimeNumericAxis,
  MemoryUsageHelper,
  Thickness,
  RubberBandXyZoomModifier,
  SeriesInfo,
  EExecuteOn,
  getRubberBandRect,
  NumberRange,
  EAutoRange,
  EAnnotationLayer,
  BoxAnnotation,
  ECoordinateMode,
  SmartDateLabelProvider,
  BaseRenderableSeries,
} from 'scichart'
import { customEvents } from '../../customEvents'
import json from '../../assets/color.json'
import { getColor } from '../../themes'
import { TooltipModifier } from './TooltipModifier'
import { MetricSettings } from '../../types'
import { toHexColor, toMetricDisplayText } from '../../utils'
import { chartGroup } from './SciChartGroup'
import { LocalDateLabelProvider } from './LocalDateLabelProvider'
import { format } from 'date-fns'
const color = json.color.base

SciChartSurface.useWasmFromCDN()
SciChartSurface.autoDisposeWasmContext = true
SciChartSurface.UseCommunityLicense()
// MemoryUsageHelper.isMemoryUsageDebugEnabled = true

const createChart = async (
  divElementId: string,
  style?: any,
  options?: MetricSettings,
  ignoreCursor?: boolean,
): Promise<SciChartAdaptor> => {
  const theme = { ...new SciChartJSDarkTheme() }
  // const theme: any = {}

  theme.sciChartBackground = 'transparent' // getColor('--chart-bg-color')
  const { sciChartSurface, wasmContext } = await SciChartSurface.create(divElementId, {
    theme,
  })

  chartGroup.addSurfaceToGroup(sciChartSurface)

  // const xAxis = new CategoryAxis(wasmContext, { labelProvider: new SmartDateLabelProvider() })
  const xAxis = new DateTimeNumericAxis(wasmContext, {
    labelProvider: new LocalDateLabelProvider(),
    // labelProvider: new SmartDateLabelProvider(),
    autoTicks: true,
    maxAutoTicks: 20,
    labelStyle: { fontSize: 11 },
    // labelProvider: new DateLabelProvider({ labelFormat: ENumericFormat.Date_DDMMYYYY }),
  })

  // xAxis.visibleRangeChanged.subscribe((arg) => {
  //   if (!arg) return
  //   const SECONDS_IN_DAY = 86400
  //   const SECONDS_IN_HOUR = 3600
  //   console.log('format')
  //   if (arg.visibleRange.max - arg.visibleRange.min < SECONDS_IN_HOUR) {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_HHMMSS
  //   } else if (arg.visibleRange.max - arg.visibleRange.min < SECONDS_IN_DAY) {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_HHMM
  //   } else {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_DDMMYYYY
  //   }
  // })

  // xAxis.visibleRangeChanged.subscribe((arg) => {
  //   if (!arg) return
  //   // console.log(`visibleRange: ${arg.visibleRange.min}-${arg.visibleRange.max}`)
  // })

  xAxis.drawMajorGridLines = false
  xAxis.drawMinorGridLines = false
  xAxis.drawMajorTickLines = false
  xAxis.drawMinorTickLines = false
  xAxis.drawMajorBands = false

  // xAxis.labelStyle.padding = new Thickness(0, 0, 2, 0)

  const yAxis = new NumericAxis(wasmContext, {
    axisAlignment: EAxisAlignment.Left,
    labelStyle: { fontSize: 12, padding: Thickness.fromString('0 0 0 0') },
    maxAutoTicks: options?.zones ? options?.zones.length : 5,
    axisTitleStyle: { fontSize: 14, padding: Thickness.fromString('0 0 0 0') },
  })
  yAxis.drawMajorGridLines = false
  yAxis.drawMinorGridLines = false
  yAxis.drawMajorBands = false
  yAxis.drawMajorTickLines = false
  yAxis.drawMinorTickLines = false
  yAxis.growBy = new NumberRange(0.05, 0.1)

  // yAxis.labelStyle.padding = new Thickness(0, 10, 0, 10)
  const labelProvider = yAxis.labelProvider
  if (options) {
    if (options.min !== undefined && options.max !== undefined) {
      yAxis.autoRange = EAutoRange.Never
      yAxis.visibleRange = new NumberRange(options.min, options.max)
      yAxis.zoomExtentsRange = new NumberRange(options.min - 0.1, options.max + 0.1)
    }

    if (options.hideValue) {
      yAxis.drawLabels = false
    }

    labelProvider.numericFormat = ENumericFormat.Decimal
    labelProvider.cursorNumericFormat = ENumericFormat.Decimal
    if (options.roundUpDigits !== undefined) {
      labelProvider.precision = options.roundUpDigits
      const n = 0
      yAxis.textFormatting = n.toFixed(options.roundUpDigits)
    } else {
      labelProvider.precision = 0
    }

    const categories = options.categories
    if (categories) {
      labelProvider.formatLabel = (value: number) => {
        return categories[value]
      }
    }

    if (!options.hideAxisTitle && options.displayName) {
      yAxis.axisTitle = options?.displayName
    }
  } else {
    labelProvider.precision = 0
  }

  // const onAttach = yAxis.onAttach.bind(yAxis)
  // yAxis.onAttach = (parentSurface: SciChartSurface, isXAxis: boolean, isPrimaryAxis: boolean) => {
  //   onAttach(parentSurface, isXAxis, isPrimaryAxis)
  //   setTimeout(() => {
  //     const canvas = sciChartSurface.domCanvas2D
  //     const svg = sciChartSurface.domSvgContainer
  //     if (canvas && svg) {
  //       // const margin = canvas.clientWidth - svg.clientWidth
  //       // console.log(canvas.clientWidth + ' / ' + svg.clientWidth)

  //       const margin = (yAxis.viewRect.x + yAxis.viewRect.width) / DpiHelper.PIXEL_RATIO
  //       console.log(margin)
  //       const event = new CustomEvent(customEvents.chartAttached, {
  //         detail: {
  //           margin,
  //         },
  //       })
  //       customEvents.publish(event)
  //     }
  //   })
  // }

  if (style) {
    if (!style.showXAxis) {
      xAxis.drawLabels = false
    }
    if (!style.showYAxis) {
      yAxis.drawLabels = false
      // yAxis.isVisible = false
    }
    if (style.padding) sciChartSurface.padding = Thickness.fromString(style.padding)
  }

  sciChartSurface.xAxes.add(xAxis)
  sciChartSurface.yAxes.add(yAxis)

  const xyDataSeries = new XyDataSeries(wasmContext)

  const lineSeries = new FastLineRenderableSeries(wasmContext)
  lineSeries.strokeThickness = 1
  lineSeries.stroke = 'rgba(255,255,255,0.7)'
  // lineSeries.rolloverModifierProps.tooltipColor = 'blue'
  lineSeries.rolloverModifierProps.tooltipColor = 'transparent'
  lineSeries.rolloverModifierProps.tooltipTextColor = getColor('--pri-ft-color')
  // lineSeries.rolloverModifierProps.tooltipLabelX = ''
  // lineSeries.rolloverModifierProps.tooltipLabelY = 'Y'
  lineSeries.dataSeries = xyDataSeries

  // lineSeries.rolloverModifierProps.tooltipLegendTemplate = (
  //   tooltipProps: RolloverModifierRenderableSeriesProps,
  //   seriesInfo: SeriesInfo,
  // ) => {
  //   return `<svg width="340" height="25">
  //       <rect width="100%" height="100%" fill="#000000DD" stroke="grey" stroke-width="2" />
  //       <svg width="100%">
  //           <text x="8" y="16" font-size="13" font-family="Verdana" fill="red">Custom Legend Tooltip</text>
  //           <text x="180" y="16" font-size="13" font-family="Verdana" fill="lightblue">X: ${seriesInfo.formattedXValue}</text>
  //           <text x="260" y="16" font-size="13" font-family="Verdana" fill="green">Y: ${seriesInfo.formattedYValue}</text>
  //       </svg>
  //   </svg>`
  // }

  sciChartSurface.renderableSeries.add(lineSeries)

  const rolloverModifier = new RolloverModifier({
    modifierGroup: 'group1',
    // snapToDataPoint: true,
    // showTooltip: false,
    rolloverLineStroke: getColor('--pri-ft-color'),
    rolloverLineStrokeThickness: 1,
  })

  const tooltipModifier = new TooltipModifier({
    modifierGroup: 'group1',
    showRolloverLine: true,
    showTooltip: true,
    // placementDivId: divElementId,
    rolloverLineStroke: getColor('--pri-ft-color'),
    rolloverLineStrokeThickness: 1,
    tooltipDataTemplate: (
      seriesInfo: SeriesInfo,
      tooltipTitle: string,
      tooltipLabelX: string,
      tooltipLabelY: string,
    ) => {
      // Lines here are returned to the tooltip and displayed as text-line per tooltip
      const lines: string[] = []
      let value: any = seriesInfo.yValue
      if (options?.categories) {
        value = options.categories[value]
      } else {
        value = toMetricDisplayText(value, options)
      }

      if (!options?.hideValue) lines.push(`${value}`)
      const date = new Date(seriesInfo.xValue * 1000)
      lines.push(`${format(date, 'h:mma')}`)
      return lines
    },
  })

  const rubberBandZoomModifier = new RubberBandXyZoomModifier({
    modifierGroup: 'group1',
    executeOn: EExecuteOn.MouseLeftButton,
  })
  rubberBandZoomModifier.xyDirection = EXyDirection.XDirection
  const mouseUp = rubberBandZoomModifier.modifierMouseUp.bind(rubberBandZoomModifier)

  rubberBandZoomModifier.modifierMouseUp = (args) => {
    mouseUp(args)
    const modifier: any = rubberBandZoomModifier
    if (args.button !== EExecuteOn.MouseLeftButton) return
    const rect = getRubberBandRect(
      modifier.pointFrom,
      modifier.pointTo,
      EXyDirection.XDirection,
      modifier.parentSurface.seriesViewRect,
    )
    if (Math.abs(rect.x - rect.right) < 1) return
    console.log(rect.x - rect.right)
    const coordCalc = xAxis.getCurrentCoordinateCalculator()
    customEvents.publish(
      customEvents.onZoom,
      new NumberRange(coordCalc.getDataValue(rect.x), coordCalc.getDataValue(rect.right)),
    )
  }

  const zoomPanModifier = new ZoomPanModifier({
    modifierGroup: 'group1',
    xyDirection: EXyDirection.XDirection,
    executeOn: EExecuteOn.MouseRightButton,
  })

  const zoomExtentsModifier = new ZoomExtentsModifier({ modifierGroup: 'group1', xyDirection: EXyDirection.XDirection })
  const cursorModifier = new CursorModifier({
    showTooltip: false,
    showAxisLabels: false,
    showXLine: false,
    showYLine: false,
  })

  if (!ignoreCursor) {
    sciChartSurface.chartModifiers.add(
      // rolloverModifier,
      tooltipModifier,
      zoomExtentsModifier,
      rubberBandZoomModifier,
      zoomPanModifier,
      // cursorModifier,
    )
  }

  customEvents.subscribe(customEvents.onVisibleRangeChanged, (e) => {
    const { data, source } = (e as CustomEvent).detail
    if (source === divElementId) return
    requestAnimationFrame(() => {
      xAxis.visibleRange = data.visibleRange
    })
  })

  // zoom to fit
  sciChartSurface.zoomExtentsX()

  if (options?.showPlotBands) {
    createPlotBands(sciChartSurface, options)
  }

  return {
    surface: sciChartSurface,
    series: lineSeries,
    xAxis,
    addSeries: () => {
      const xyDataSeries = new XyDataSeries(wasmContext)
      const series = new FastLineRenderableSeries(wasmContext)
      series.strokeThickness = 1
      series.stroke = color.green[60].value
      series.dataSeries = xyDataSeries
      sciChartSurface.renderableSeries.add(series)
      return series
    },
  }
}

export type SciChartAdaptor = {
  surface?: SciChartSurface
  series?: BaseRenderableSeries
  xAxis?: DateTimeNumericAxis
  overview?: SciChartOverview
  addSeries?: () => BaseRenderableSeries
}

export function SciChart({
  chartRef,
  className,
  containerStyle,
  style,
  showOverview,
  options,
  onLoad,
  ignoreCursor,
}: {
  chartRef?: React.MutableRefObject<SciChartAdaptor | undefined>
  className?: string
  containerStyle?: CSSProperties
  style?: any
  showOverview?: boolean
  ignoreCursor?: boolean
  options?: MetricSettings
  onLoad?: (chart: SciChartAdaptor) => void
}) {
  const [rootElementId] = useState(`chart-root-${generateGuid()}`)
  const sciChartSurfaceRef = useRef<SciChartAdaptor>()

  useEffect(() => {
    const chartInitializationPromise = createChart(rootElementId, style, options, ignoreCursor)

    chartInitializationPromise.then((initResult) => {
      if (!initResult) return
      sciChartSurfaceRef.current = initResult
      if (chartRef) chartRef.current = initResult
      onLoad && onLoad(initResult)
      return initResult
    })

    const performCleanup = () => {
      const chart = sciChartSurfaceRef.current
      if (!chart) return
      chartGroup.removeSurface(chart.surface!)
      chart.series?.delete()
      chart.xAxis?.visibleRangeChanged.unsubscribeAll()
      chart.xAxis?.delete()
      chart.overview?.delete()
      chart.surface?.delete()
      sciChartSurfaceRef.current = undefined
    }

    return () => {
      // check if chart is already initialized or wait init to finish before deleting it
      sciChartSurfaceRef.current ? performCleanup() : chartInitializationPromise.then(performCleanup)
    }
  }, [])

  return (
    <>
      <div id={rootElementId} className={className} style={containerStyle} />
    </>
  )
}

function createPlotBands(sciChartSurface: SciChartSurface, options: MetricSettings) {
  if (!options.colors || !options.zones) return

  for (let i = 0; i < options.colors.length; i++) {
    const c = toHexColor(options.colors[i]) + '33'
    sciChartSurface.annotations.add(
      new BoxAnnotation({
        y2: options.zones[i + 1],
        y1: options.zones[i],
        x1: 0,
        x2: 1,
        strokeThickness: 0,
        fill: c,
        xCoordinateMode: ECoordinateMode.Relative,
        annotationLayer: EAnnotationLayer.BelowChart,
      }),
    )
  }
}
