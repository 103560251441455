import React, { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useActivePatient, useWard, useMetricSettings, useFirebaseRoutesAnalytics, usePatientFilter } from '../../hooks'
import { inactiveSymbol } from '../../Constants'
import { Aggregate, AppState, Patient } from '../../types'
import { usePatientList } from '../../hooks/usePatientList'
import { Info } from '../common'
import { toMetricDisplayText, toPatientDisplayText } from '../../utils'
import { useSelector } from 'react-redux'

export const PatientListPage: FC = () => {
  const { wardId } = useParams()
  const { loading, data } = useWard(parseInt(wardId || ''))
  const activePatientIds = data ? data.activePatientIds : []
  useFirebaseRoutesAnalytics()

  if (loading) return <Info text="Loading ..." />

  return (
    <div className="v-contentView v-contentView--largeMargin">
      <div className="v-contentArea">
        <div className="v-fixedWidthWrapper">
          <div className="v-row">
            <div className="v-contentTitle">Patients</div>
            {/* <div id="refine" className="" onClick={undefined}>
              Refine
              <span className="fontIcon">filter</span>
            </div> */}
          </div>
          <PatientList data={activePatientIds} />
        </div>
      </div>
    </div>
  )
}

const PatientList: FC<{ data: string[] }> = ({ data }) => {
  const fields = ['respiratoryrate', 'heartrate', 'spO2']
  const patients = usePatientFilter(usePatientList(data))

  return (
    <div>
      <div className="v-row v-row--header">
        <div className="v-row-item--wide">Name / ID</div>
        {fields.map((f) => (
          <div key={f} className={`v-row-item ${f === 'spO2' ? 'v-row-item--short' : null}`}>
            {useMetricSettings()[f]?.displayName}
          </div>
        ))}
      </div>
      {patients.map((patient) => (
        <PatientInfoView key={patient.id} patient={patient} />
      ))}
    </div>
  )
}

const PatientInfoView: FC<{ patient: Patient }> = ({ patient }) => {
  const activePatient = useActivePatient(patient.id)
  const metricSettings = useMetricSettings()
  const aggregates = activePatient?.devices.flatMap((x) => x.aggregates) || []

  const findAggregate = (name: string): Aggregate | undefined => {
    return aggregates.find((x) => x.name === name) ?? undefined
  }

  const resp = findAggregate('respiratoryrate')
  const hr = findAggregate('heartrate')
  const sp = findAggregate('spO2')

  return (
    <Link className="v-row v-row--patient" key={patient.id} to={`./${patient.id}`} state={activePatient}>
      <div className="v-row-item v-row-item--wide v-row-item--info">
        <div className="v-row-item--name">{toPatientDisplayText(patient)}</div>
        <div className="v-row-item--id">ID {patient.id}</div>
      </div>
      <div className="v-row-item" key="respiratoryrate">
        <span className="v-row-item--data">{toMetricDisplayText(resp?.value, metricSettings[resp?.name || ''])}</span>
        <span className="v-row-item--unit">{metricSettings[resp?.name || '']?.unit}</span>
      </div>
      <div className="v-row-item" key="heartrate">
        <span className="v-row-item--data">{toMetricDisplayText(hr?.value, metricSettings[hr?.name || ''])}</span>
        <span className="v-row-item--unit">{metricSettings[hr?.name || '']?.unit}</span>
      </div>
      <div className="v-row-item v-row-item--short" key="spO2">
        <span className="v-row-item--data">{toMetricDisplayText(sp?.value, metricSettings[sp?.name || ''])}</span>
        <span className="v-row-item--unit">{metricSettings[sp?.name || '']?.unit}</span>
      </div>
    </Link>
  )
}
