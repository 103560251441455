import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './assets/css/index.css'
import { store } from './store'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { initializeTheme } from './themes'
import {
  AuthHandler,
  AuthProvider,
  DefaultApolloProvider,
  FirebaseAppProvider,
  InstanceSettingProvider,
} from './infrastructure'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { InitialiseSentry, SentryContextProvider } from './infrastructure/SentryProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

InitialiseSentry()

initializeTheme()

root.render(
  <SentryContextProvider>
    <FirebaseAppProvider>
      <InstanceSettingProvider>
        <AuthProvider>
          <AuthHandler>
            <DefaultApolloProvider>
              <Provider store={store}>
                <BrowserRouter>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                  </LocalizationProvider>
                </BrowserRouter>
              </Provider>
            </DefaultApolloProvider>
          </AuthHandler>
        </AuthProvider>
      </InstanceSettingProvider>
    </FirebaseAppProvider>
  </SentryContextProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
