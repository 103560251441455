import { ApolloClient, ApolloQueryResult, FetchResult, gql, Observable } from '@apollo/client'
import { DateRangeTimestamp, DeviceDataSet, MetricSettings } from '../types'
import { ConcurrentQueue } from './ConcurrentQueue'

const QUERY_PATIENT_DATAPOINTSERIES = gql`
  query DeviceDataSeries(
    $patientId: UUID!
    $deviceId: String!
    $metricName: String!
    $start: DateTime!
    $stop: DateTime
  ) {
    deviceDataSeries(patientId: $patientId, deviceId: $deviceId, metricName: $metricName, start: $start, stop: $stop) {
      name
      dataPoints
    }
  }
`

const ON_NEW_DEVICE_DATAPOINT_SUBSCRIPTION = gql`
  subscription OnNewPatientDeviceDataPoint($patientId: ID!) {
    newPatientDeviceDataPoints(patientId: $patientId) {
      deviceId
      series {
        name
        dataPoints
      }
    }
  }
`

type PatientDeviceDataPointResponse = {
  newPatientDeviceDataPoints: DeviceDataSet
}

export class PatientService implements IPatientService {
  private client: ApolloClient<object>
  private patientId: string
  private observable?: Observable<FetchResult<PatientDeviceDataPointResponse>>
  private taskQueue: ConcurrentQueue
  private subscriberCount = 0
  private timeScale: number

  constructor(patientId: string, client: ApolloClient<object>, timeScale?: number) {
    this.client = client
    this.patientId = patientId
    this.taskQueue = new ConcurrentQueue()
    this.timeScale = timeScale ?? 1
  }

  public async queryData(
    device: string,
    metric: string,
    range: DateRangeTimestamp,
    setting: MetricSettings,
  ): Promise<number[][]> {
    if (!range.start) return Promise.resolve([])
    const queryStart = new Date(range.start)
    const queryEnd = range.end ? new Date(range.end) : undefined
    // let s = 0
    const task = () => {
      // s = performance.now()
      return this.client.query({
        query: QUERY_PATIENT_DATAPOINTSERIES,
        variables: {
          patientId: this.patientId,
          deviceId: device,
          metricName: metric,
          start: queryStart,
          stop: queryEnd,
        },
        fetchPolicy: 'no-cache',
      })
    }

    const response = await this.taskQueue.enqueue<ApolloQueryResult<any>>(task)
    // console.log(metric, (performance.now() - s) / 1000)
    if (response.error) {
      // Todo*
      console.warn(response.error)
    }

    const dataPoints: number[][] = response.data.deviceDataSeries?.dataPoints ?? []

    const result: number[][] = []
    for (let i = 0; i < dataPoints.length; i++) {
      const data = dataPoints[i]
      result.push([data[0] / this.timeScale, data[1]])

      if (this.timeScale !== 1 && i < dataPoints.length - 1) {
        const minuteInMs = 60000
        const next = dataPoints[i + 1]
        const gapSize = setting?.gapThresholdMinutes ?? 2
        if (gapSize * minuteInMs < next[0] - data[0]) {
          result.push([data[0] / this.timeScale + 1, NaN])
          result.push([next[0] / this.timeScale - 1, NaN])
        }
      }
    }
    return result
  }

  public onNewDataPoints(deviceId: string, metricName: string, callback: (data: number[][]) => void) {
    if (!this.observable) {
      this.observable = this.client.subscribe({
        query: ON_NEW_DEVICE_DATAPOINT_SUBSCRIPTION,
        variables: { patientId: this.patientId },
      })
    }

    this.subscriberCount++
    const subscription = this.observable.subscribe((nextResult) => {
      if (nextResult.data?.newPatientDeviceDataPoints.deviceId !== deviceId) return
      const series = nextResult.data?.newPatientDeviceDataPoints.series ?? []

      const dataPoints = series?.find((x: { name: string }) => x.name === metricName)?.dataPoints ?? []
      callback(dataPoints.map((d) => [d[0] / this.timeScale, d[1]]))
    })

    return () => {
      subscription.unsubscribe()
      this.subscriberCount--
      if (this.subscriberCount < 1) {
        this.observable = undefined
        this.subscriberCount = 0
      }
    }
  }
}

export interface IPatientService {
  queryData(
    device: string,
    metric: string,
    dateRange: DateRangeTimestamp,
    setting?: MetricSettings,
  ): Promise<number[][]>
  onNewDataPoints(deviceId: string, metricName: string, callback: (data: number[][]) => void): () => void
}
