import { FC, useCallback, useEffect, useState } from 'react'
import { customEvents } from '../../customEvents'
import { switchTheme, activeThemeName } from '../../themes'
import { Patient, RangeButton, ToolbarButtonEvent } from '../../types'
import { rangeSelectorConfig } from '../chart/configs'
import { DatePickerModal } from '../common'
import { subDays } from 'date-fns'
import { logEvent } from 'firebase/analytics'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useWasmSetting, wasmChartKey } from '../../hooks'

// const counter = 0

export const Toolbar: FC<{ patient: Patient }> = ({ patient }) => {
  const timeRangeButtons = rangeSelectorConfig.buttons!
  const [activeId, setActive] = useState<number>(rangeSelectorConfig.selected ?? -1)
  // const client = useApolloClient()
  const { wasmEnabled } = useWasmSetting()
  const analytics = useAnalytics()

  useEffect(() => {
    const unsubscribe = customEvents.subscribe(customEvents.onChartSelect, (event: Event) => {
      setActive(-1)
    })

    return unsubscribe
    //
  }, [])

  const handleClick = useCallback((index: number, button: RangeButton) => {
    setActive(index)
    setTimeout(() => {
      customEvents.publish<ToolbarButtonEvent>(customEvents.onToolbarButtonClick, {
        id: index,
        button,
      })
      logEvent(analytics, 'select_content', {
        content_type: 'rangeSelectorButton',
        content_id: button.text,
      })
    })
  }, [])

  return (
    <div className="v-toolbarWrapper u-noSelect">
      <div className="v-toolbar">
        <div
          className="v-toolbar-button v-toolbar-button--icon"
          onClick={() => {
            switchTheme()
            window.location.reload()
          }}
        >
          <span className="fontIcon">{activeThemeName === 'dark' ? 'sun' : 'moon'}</span>
        </div>
        {patient.firstSessionDate && patient.firstSessionDate <= subDays(new Date(), 14) ? (
          <div className="v-toolbar-button v-toolbar-button--icon">
            <DatePickerModal patient={patient} />
          </div>
        ) : null}
        {wasmEnabled ? (
          <div className="v-toolbar-button v-toolbar-button--icon">
            <div
              className="fontIcon u-noSelect"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const data = localStorage.getItem(wasmChartKey)
                if (!data) {
                  localStorage.setItem(wasmChartKey, '1')
                } else {
                  localStorage.removeItem(wasmChartKey)
                }
                window.location.reload()
              }}
            >
              update
            </div>
          </div>
        ) : undefined}
        {/* <div
        className="v-toolbar-button"
        onClick={() => {
          dispatch(uiSlice.actions.toggleControl(null))
        }}
      >
        x
      </div>
      */}
        {/* <div
        onClick={() => {
          addMetric('heartrate', client)
        }}
        className="v-toolbar-button u-noSelect"
      >
        a
      </div>
      <div
        onClick={() => {
          addMetric('heartrate1', client)
        }}
        className="v-toolbar-button u-noSelect"
      >
        b
      </div>
      <div
        onClick={() => {
          addMetric('metric' + ++counter, client)
        }}
        className="v-toolbar-button u-noSelect"
      >
        c
      </div>
      <br />
      <div
        onClick={() => {
          addDevice('FakeDevice' + ++counter, 'fakemetric', client)
        }}
        className="v-toolbar-button u-noSelect"
      >
        x
      </div>
      <div
        onClick={() => {
          addDevice('FakeDevice1', 'fakemetric' + ++counter, client)
        }}
        className="v-toolbar-button u-noSelect"
      >
        y
      </div>*/}
        <br />
        {timeRangeButtons.map((button, index) => {
          return (
            <div
              onClick={() => {
                handleClick(index, button as RangeButton)
              }}
              className={`v-toolbar-button ${index === activeId ? 'is-active' : ''}`}
              key={index}
            >
              {button.text}
            </div>
          )
        })}
      </div>
    </div>
  )
}
