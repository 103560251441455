import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Patient } from '../../types'
import { ExportModal } from '../common/ExportModal'
import { DeletePatientModal } from '../common/DeletePatientModal'
import { toPatientDisplayText } from '../../utils'

export const PatientHeader: FC<{ patient: Patient }> = ({ patient }) => {
  const navigate = useNavigate()

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <div className="v-header">
      <div onClick={handleBack} className="v-header-item v-header-item--backButton u-noSelect">
        Back
      </div>
      <div className="v-header-item v-header-item--bold">{toPatientDisplayText(patient)}</div>
      {/* <div className="v-header-item">DOB: {patient.dob?.toLocaleDateString()}</div> */}
      <div className="v-header-item">ID: {patient.id}</div>
      {/* <div className="v-header-item">Room: 26</div> */}
      <div className="v-header-item v-header-item--alignRight">
        <div className="v-row u-noSelect">
          <ExportModal patient={patient} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <DeletePatientModal patientId={patient.id} />
        </div>
      </div>
    </div>
  )
}
