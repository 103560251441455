import { useModulesSettings } from '.'
import { PatientModuleSettings } from '../types'

export const wasmChartKey = 'wasm-chart'

export const useWasmSetting = (): { wasmEnabled: boolean; wasmChartEnabled?: boolean } => {
  const settings = useModulesSettings()['patient'] as PatientModuleSettings

  if (!settings.enableWasm) return { wasmEnabled: false }
  const data = localStorage.getItem(wasmChartKey)
  return { wasmEnabled: true, wasmChartEnabled: data === '1' }
}
