import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

const RELEASE_PATIENT_MUTATION = gql`
  mutation ReleasePatient($patientId: ID!, $wardId: Int!) {
    releasePatient(input: { patientId: $patientId, wardId: $wardId }) {
      boolean
    }
  }
`

export const useReleasePatient = (patientId: string, wardId: number) => {
  const [error, setError] = useState<string>()
  const [releasePatient, { loading }] = useMutation(RELEASE_PATIENT_MUTATION, {
    variables: {
      patientId,
      wardId,
    },
    onCompleted: (data) => {
      const success = data.releasePatient.boolean
      if (!success) {
        setError('Delete patient failed')
        return
      }

      window.history.replaceState(null, '', '.')
      window.history.back()
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  return { releasePatient, loading, error }
}
