import { Chart, Options, TooltipPositionerPointObject } from 'highcharts/highstock'
import { getVar } from '../../../layout'
import { rangeSelectorConfig } from './NavigatorConfig'
import { inactiveSymbol } from '../../../Constants'
import { toHexColor, toMetricDisplayText } from '../../../utils'
import { useMetricSettings } from '../../../hooks'
import { AverageSeriesOptions, ChartSelectionEvent, MetricSettings, StockChartOptions } from '../../../types'
import { customEvents } from '../../../customEvents'
import json from '../../../assets/color.json'
import { format } from 'date-fns'

const color = json.color.base

const markers: Record<string, any[]> = {}

export const resetMarkers = (chart?: Chart) => {
  if (!chart) {
    for (const key of Object.keys(markers)) {
      markers[key].forEach((m) => m.destroy())
      delete markers[key]
    }
    return
  }
  markers[chart.index]?.forEach((m) => m.destroy())
  markers[chart.index] = []
}

function drawMarker(chart: Chart, point: TooltipPositionerPointObject) {
  const xAxis: any = chart.xAxis[0]
  const yAxis: any = chart.yAxis[0]
  const x = point.plotX + xAxis.left
  const y = point.plotY + yAxis.top
  const fillColor =
    chart.series.length > 1 && chart.series[chart.series.length - 1].visible
      ? chart.series[chart.series.length - 1].color
      : chart.series[0].color
  const marker = chart.renderer.circle(x, y, 3).attr({ fill: fillColor, opacity: 0.7 }).add()
  markers[chart.index].push(marker)
}

const getTooltip = (value: string | number, date: Date, hideValue?: boolean) => {
  const marginOffset = 'margin-bottom:-3px'
  const timeLabel = `<div style="text-align:right;font-size:12px;${hideValue ? '' : marginOffset}">${format(
    date,
    'h:mmaaa',
  )}</div>`
  return hideValue
    ? timeLabel
    : `<div style='text-align:right;font-size:14px;padding-bottom:0px'>${value}</div>` + timeLabel
}

const getPaddedCategories = (settings?: MetricSettings) => {
  if (!settings?.categories) return undefined
  const categories = settings?.categories
  if (settings?.min !== undefined && settings.min > 0) {
    const placeholder = []
    for (let i = 0; i < settings.min; i++) {
      placeholder.push('')
    }
    return placeholder.concat(categories)
  }
  return categories
}

const handleChartSelection = (event: any) => {
  customEvents.publish<ChartSelectionEvent>(customEvents.onChartSelect, {
    min: event.xAxis[0].min,
    max: event.xAxis[0].max,
  })
}

export const getStockChartConfig = ({ height, name, gapSize, backgroundColor, fontColor }: StockChartOptions) => {
  const settings = useMetricSettings()[name || '']
  const leftMargin = getVar('chart-left-margin')
  const plotBand: any = settings?.showPlotBands ? [] : undefined
  const padding = 15

  const showValue = settings?.hideValue !== true
  let tickAmount = undefined
  if (showValue) {
    tickAmount = settings?.max !== undefined && settings.max < 10 ? undefined : 5
  }

  if (plotBand) {
    if (settings && settings.colors && settings.zones) {
      for (let i = 0; i < settings.colors.length; i++) {
        const band = {
          from: settings.zones[i],
          to: settings.zones[i + 1],
          color: toHexColor(settings.colors[i]) + '33',
        }
        plotBand.push(band)
      }
    }
  }

  const chartOptions = {
    height: height ?? 200,
    margin: [padding, 0, padding, leftMargin],
    spacing: [0, 0, 0, 0],
    backgroundColor: backgroundColor,
    zooming: {
      resetButton: {
        theme: {
          style: {
            display: 'none',
          },
        },
      },
    },
    zoomType: 'x',
    events: {
      selection: function (e: any) {
        handleChartSelection(e)
        resetMarkers()
        return true
      },
    },
  }

  const stockChartConfig: Options = {
    chart: chartOptions,
    loading: {
      labelStyle: {
        fontSize: '0px',
      },
      style: {
        opacity: 1,
        backgroundColor,
        color: backgroundColor,
      },
    },
    accessibility: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    tooltip: {
      enabled: false,
      hideDelay: 60000,
      padding: 2,
      useHTML: true,
      outside: false,
      shape: 'square',
      backgroundColor: backgroundColor + 'dd',
      borderColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      style: {
        color: fontColor,
      },
      formatter: function () {
        if (this.y === null || this.y === undefined || this.x === undefined) return inactiveSymbol
        const date = new Date(this.x)
        const text = toMetricDisplayText(String(this.y), settings)
        return getTooltip(text, date, settings?.hideValue)
      },
      positioner: function (w, h, point) {
        const chart = point.series.chart
        const xAxis: any = chart.xAxis[0]
        const x = point.plotX + xAxis.left
        resetMarkers(chart)
        drawMarker(chart, point)
        return { x: x - w - 5, y: chart.chartHeight }
      },
      //   xDateFormat: '%Y-%m-%d',
    },
    rangeSelector: rangeSelectorConfig,
    title: {
      text: undefined,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      ordinal: false,
      type: 'datetime',
      crosshair: {
        snap: false,
      },
      dateTimeLabelFormats: {
        hour: '%l:%M %P',
      },
      labels: {
        style: { color: fontColor, fontSize: '10px' },
      },
      minPadding: 0,
      maxPadding: 0,
      lineWidth: 0,
      tickWidth: 0,
      events: {
        afterSetExtremes: function (e) {
          setTimeout(() => {
            if (!this.chart) return
            resetMarkers(this.chart)
            this.chart.update({ tooltip: { enabled: false } })
          }, 4000)
        },
      },
    },
    yAxis: {
      title: settings?.hideAxisTitle
        ? undefined
        : {
          text: settings?.displayName ?? name,
          style: {
            color: fontColor,
            fontSize: '14px',
          },
        },
      labels: {
        style: { color: fontColor, fontSize: settings?.hideAxisTitle ? '10px' : undefined },
        allowOverlap: true,
        enabled: showValue,
        y: 5,
      },
      plotBands: plotBand,
      categories: getPaddedCategories(settings),
      allowDecimals: false,
      gridLineColor: '#000', // Todo*
      gridLineDashStyle: 'Dot',
      gridLineWidth: settings?.showPlotBands ? undefined : 1,
      opposite: false,
      min: settings?.min !== undefined ? settings.min : undefined,
      max: settings?.max !== undefined ? settings.max : undefined,
      tickAmount,
      endOnTick: false,
      showLastLabel: true,
      maxPadding: 0,
      minPadding: 0,
    },
    series: [
      {
        name: 'value',
        type: 'line',
        findNearestPointBy: 'x',
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    plotOptions: {
      series: {
        gapUnit: 'value',
        gapSize: gapSize ? gapSize * 60000 : undefined,
        lineWidth: 1,
        color: fontColor,
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
          },
        },
        events: {
          show: function () {
            resetMarkers(this.chart)
          },
          hide: function () {
            resetMarkers(this.chart)
          },
        },
        // point: {
        //   events: {
        //     mouseOut: function () {
        //       console.log('mouse out')
        //     },
        //     mouseOver: function () {
        //       console.log('mouse over')
        //     },
        //   },
        // },
      },
    },
    credits: {
      enabled: false,
    },
  }

  return stockChartConfig
}

export const getAverageSeriesConfig = ({ data, id, name, gapSize }: AverageSeriesOptions) => ({
  data,
  id: id,
  axisId: id,
  name,
  type: 'line',
  gapSize: gapSize ? gapSize * 60000 : undefined,
  color: color.green[60].value,
  states: {
    inactive: {
      opacity: 1,
    },
    hover: {
      enabled: false,
    },
  },
  marker: {
    enabled: false,
  },
})
