import { startOfDay, endOfDay } from 'date-fns'
import { saveAs } from 'file-saver'
import { DataExportRequest, ExportInfo, FileDownloadRequest, FileRecordRequest } from '../types'

const url = window.location.hostname === 'localhost' ? 'localhost:5001' : 'api.' + window.location.host

export const sendExportRequest = (data: DataExportRequest, token: string): Promise<string> => {
  data.start = startOfDay(data.start)
  data.stop = endOfDay(data.stop)

  return post(`https://${url}/v1/export`, token, data)
    .then((response) => {
      return response.text()
    })
    .then((text) => {
      return text
    })
}

export const getExportInfo = (data: FileRecordRequest, token: string): Promise<ExportInfo[]> => {
  return get(`https://${url}/v1/export/${data.patientId}`, token)
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      return data.map((d: any) => ({
        patientId: d.patientId,
        file: d.file,
        status: d.status,
        sampleRate: d.sampleRate,
        start: new Date(d.start),
        end: new Date(d.end),
        created: new Date(d.created),
      }))
    })
}

export const deleteExport = (patientId: string, token: string): Promise<Response> => {
  return callDelete(`https://${url}/v1/export/${patientId}`, token)
}

export const downloadZip = (data: FileDownloadRequest, token: string): Promise<number> => {
  return post(`https://${url}/v1/export/download`, token, data)
    .then((response) => {
      return response.blob()
    })
    .then((blob) => {
      if (!blob.size) {
        return 0
      }
      saveAs(blob, `${new Date().toISOString()}.zip`)
      return 1
    })
}

const get = (url: string, token: string): Promise<Response> => {
  return fetch(url, {
    method: 'GET',
    headers: getHeader(token),
  }).then((response) => {
    if (!response.ok) {
      throw 'Network response: ' + response.status
    }
    return response
  })
}

const callDelete = (url: string, token: string): Promise<Response> => {
  return fetch(url, {
    method: 'DELETE',
    headers: getHeader(token),
  }).then((response) => {
    if (!response.ok) {
      throw 'Network response: ' + response.status
    }
    return response
  })
}

const post = (url: string, token: string, data: any): Promise<Response> => {
  return fetch(url, {
    method: 'POST',
    headers: getHeader(token),
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw 'Network response: ' + response.status
    }
    return response
  })
}

const getHeader = (token: string) => {
  return {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json;charset=utf-8',
    Authorization: `Bearer ${token}`,
  }
}
