import { CSSProperties, useState, useRef, useEffect } from 'react'
import {
  SciChartSurface,
  NumericAxis,
  XyDataSeries,
  FastLineRenderableSeries,
  RolloverModifier,
  MouseWheelZoomModifier,
  EXyDirection,
  ZoomPanModifier,
  ZoomExtentsModifier,
  CursorModifier,
  generateGuid,
  SciChartOverview,
  EAxisAlignment,
  SciChartJSDarkTheme,
  ENumericFormat,
  DateTimeNumericAxis,
  DateLabelProvider,
  MemoryUsageHelper,
  Thickness,
  RubberBandXyZoomModifier,
  DpiHelper,
  EExecuteOn,
  SciChartJSLightTheme,
  EAutoColorMode,
} from 'scichart'
import { SmartDateLabelProvider } from 'scichart/Charting/Visuals/Axis/LabelProvider/SmartDateLabelProvider'
import { customEvents } from '../../customEvents'
import json from '../../assets/color.json'
import { activeThemeName, getColor } from '../../themes'
import { SciChartAdaptor } from './SciChart'
import { chartGroup } from './SciChartGroup'
import { LocalDateLabelProvider } from '../scichart/LocalDateLabelProvider'
const color = json.color.base

SciChartSurface.useWasmFromCDN()
SciChartSurface.autoDisposeWasmContext = true
// MemoryUsageHelper.isMemoryUsageDebugEnabled = true

const createChart = async (divElementId: string, style?: any): Promise<SciChartAdaptor> => {
  const theme = { ...new SciChartJSDarkTheme() }
  // const theme: any = {}
  theme.sciChartBackground = 'transparent' // getColor('--chart-bg-color')
  const { sciChartSurface, wasmContext } = await SciChartSurface.create(divElementId, {
    theme,
  })

  chartGroup.addSurfaceToGroup(sciChartSurface)

  // const xAxis = new CategoryAxis(wasmContext, { labelProvider: new SmartDateLabelProvider() })
  const xAxis = new DateTimeNumericAxis(wasmContext, {
    labelProvider: new LocalDateLabelProvider(),
    // labelProvider: new DateLabelProvider({ labelFormat: ENumericFormat.Date_DDMMYYYY }),
  })

  // xAxis.visibleRangeChanged.subscribe((arg) => {
  //   if (!arg) return
  //   const SECONDS_IN_DAY = 86400
  //   const SECONDS_IN_HOUR = 3600
  //   console.log('format')
  //   if (arg.visibleRange.max - arg.visibleRange.min < SECONDS_IN_HOUR) {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_HHMMSS
  //   } else if (arg.visibleRange.max - arg.visibleRange.min < SECONDS_IN_DAY) {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_HHMM
  //   } else {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_DDMMYYYY
  //   }
  // })

  xAxis.drawMajorGridLines = false
  xAxis.drawMinorGridLines = false
  xAxis.drawMajorTickLines = false
  xAxis.drawMinorTickLines = false
  xAxis.drawMajorBands = false
  xAxis.labelStyle.fontSize = 12
  xAxis.labelStyle.padding = new Thickness(0, 0, 2, 0)

  const yAxis = new NumericAxis(wasmContext, {
    axisAlignment: EAxisAlignment.Left,
    labelStyle: { fontSize: 12 },
  })
  yAxis.drawMajorGridLines = false
  yAxis.drawMinorGridLines = false
  yAxis.drawMajorBands = false
  yAxis.drawMajorTickLines = false
  yAxis.drawMinorTickLines = false
  // yAxis.labelStyle.padding = new Thickness(0, 0, 0, 0)
  yAxis.drawLabels = false

  // const onAttach = yAxis.onAttach.bind(yAxis)
  // yAxis.onAttach = (parentSurface: SciChartSurface, isXAxis: boolean, isPrimaryAxis: boolean) => {
  //   onAttach(parentSurface, isXAxis, isPrimaryAxis)
  //   setTimeout(() => {
  //     const canvas = sciChartSurface.domCanvas2D
  //     const svg = sciChartSurface.domSvgContainer
  //     if (canvas && svg && yAxis.viewRect) {
  //       const margin = (yAxis.viewRect.left + yAxis.viewRect.width) / DpiHelper.PIXEL_RATIO
  //       const width = canvas.clientWidth - margin
  //       console.log(margin)
  //       customEvents.publish(customEvents.chartAttached, {
  //         margin,
  //         width,
  //       })
  //     }
  //   })
  // }

  if (style) {
    if (!style.showXAxis) {
      xAxis.drawLabels = false
    }
    if (!style.showYAxis) {
      yAxis.isVisible = false
    }
    // if (style.padding) sciChartSurface.padding = Thickness.fromString(style.padding)
  }
  sciChartSurface.padding = Thickness.fromNumber(0)

  sciChartSurface.xAxes.add(xAxis)
  sciChartSurface.yAxes.add(yAxis)
  const xyDataSeries = new XyDataSeries(wasmContext)
  const lineSeries = new FastLineRenderableSeries(wasmContext)
  lineSeries.strokeThickness = 1
  lineSeries.stroke = 'rgba(255,255,255,0.7)'
  // lineSeries.rolloverModifierProps.tooltipColor = 'green'
  // lineSeries.rolloverModifierProps.tooltipLabelX = 'X'
  // lineSeries.rolloverModifierProps.tooltipLabelY = 'Y'
  lineSeries.dataSeries = xyDataSeries
  sciChartSurface.renderableSeries.add(lineSeries)

  const rolloverModifier = new RolloverModifier({ modifierGroup: 'group1' })
  const rubberBandZoomModifier = new RubberBandXyZoomModifier({ modifierGroup: 'group1' })
  rubberBandZoomModifier.xyDirection = EXyDirection.XDirection
  const zoomExtentsModifier = new ZoomExtentsModifier({ modifierGroup: 'group1' })
  const cursorModifier = new CursorModifier({ showTooltip: false, showAxisLabels: false, showXLine: false })
  sciChartSurface.chartModifiers.add(
    // rolloverModifier,
    zoomExtentsModifier,
    // rubberBandZoomModifier,
    cursorModifier,
  )

  // customEvents.subscribe(customEvents.onVisibleRangeChanged, (e) => {
  //   if (zoomInProgress) return
  //   const { data, source } = (e as CustomEvent).detail
  //   if (source === divElementId) return
  //   requestAnimationFrame(() => {
  //     xAxis.visibleRange = data.visibleRange
  //   })
  // })

  customEvents.subscribe(customEvents.onZoom, (e) => {
    const data = (e as CustomEvent).detail
    requestAnimationFrame(() => {
      // xAxis.zoom(data.min, data.max)
      xAxis.visibleRange = data
    })
  })

  // zoom to fit
  sciChartSurface.zoomExtents()

  return {
    surface: sciChartSurface,
    series: lineSeries,
    xAxis,
    addSeries: () => {
      const xyDataSeries = new XyDataSeries(wasmContext)
      const series = new FastLineRenderableSeries(wasmContext)
      series.strokeThickness = 1
      series.stroke = color.green[60].value
      series.dataSeries = xyDataSeries
      sciChartSurface.renderableSeries.add(series)
      return series
    },
  }
}

export const createChartWithOverview = async (divElementId: string, overviewElementId: string, style?: any) => {
  const result = await createChart(divElementId)
  const { surface } = result
  if (!surface) return
  const mouseWheelZoomModifier = new MouseWheelZoomModifier()
  mouseWheelZoomModifier.xyDirection = EXyDirection.XDirection
  const zoomPanModifier = new ZoomPanModifier({
    modifierGroup: 'group1',
    xyDirection: EXyDirection.XDirection,
    executeOn: EExecuteOn.MouseRightButton,
  })

  surface.chartModifiers.add(zoomPanModifier, mouseWheelZoomModifier)

  const bg = activeThemeName === 'light' ? 'rgb(255,255,255)' : 'rgb(70,70,70)'
  const selectionOpacity = activeThemeName === 'light' ? '0.1' : '1'
  const overview = await SciChartOverview.create(surface, overviewElementId)
  const overviewSurface = overview.overviewSciChartSurface
  overviewSurface.padding = new Thickness(0, 0, 0, 0)
  overviewSurface.background = 'transparent'
  overview.rangeSelectionModifier.rangeSelectionAnnotation.svgString = `<svg width="50" height="50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="100%" height="100%" style="fill:rgb(0,0,0)" opacity="${selectionOpacity}">
  </rect>
  </svg>`
  // Customize the unselected area
  overview.rangeSelectionModifier.unselectedsvgString = `<svg width="50" height="50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="100%" height="100%" style="fill:${bg}">
  </rect>
  </svg>`
  overview.rangeSelectionModifier.rangeSelectionAnnotation.adornerSvgStringTemplate = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ) => {
    const delta = 3
    const ADORNER_GRIP_RADIUS = 10
    return `<svg xmlns="http://www.w3.org/2000/svg">
      <line x1="${x2}" y1="${y1 + delta}" x2="${x2}" y2="${
  y2 - delta
}" stroke="rgb(185, 185, 185)" stroke-width="10" stroke-linecap="round" />
      <line x1="${x1}" y1="${y1 + delta}" x2="${x1}" y2="${
  y2 - delta
}" stroke="rgb(185, 185, 185)" stroke-width="10" stroke-linecap="round" />
      </svg>`
  }
  // overview.overviewYAxis.isVisible = true
  // overview.overviewYAxis.labelProvider.formatLabel = (dataValue) => '   '
  chartGroup.addSurfaceToGroup(overview.overviewSciChartSurface)

  result.overview = overview

  result.xAxis?.visibleRangeChanged.subscribe((data) => {
    customEvents.publish(customEvents.onVisibleRangeChanged, {
      data,
      source: divElementId,
    })
  })

  return result
}

export function SciOverview({
  chartRef,
  className,
  containerStyle,
  style,
  onLoad,
}: {
  chartRef?: React.MutableRefObject<SciChartAdaptor | undefined>
  className?: string
  containerStyle?: CSSProperties
  style?: any
  onLoad?: (chart: SciChartAdaptor) => void
}) {
  const [rootElementId] = useState(`chart-root-${generateGuid()}`)
  const [overviewElementId] = useState(`chart-overview-${generateGuid()}`)
  const [margin, setMargin] = useState<number>()
  const [width, setWidth] = useState<number>()
  const sciChartSurfaceRef = useRef<SciChartAdaptor>()

  useEffect(() => {
    const chartInitializationPromise = createChartWithOverview(rootElementId, overviewElementId, style)

    chartInitializationPromise.then((initResult) => {
      if (!initResult) return
      sciChartSurfaceRef.current = initResult
      if (chartRef) chartRef.current = initResult
      onLoad && onLoad(initResult)
      return initResult
    })

    const performCleanup = () => {
      const chart = sciChartSurfaceRef.current
      if (!chart) return
      chartGroup.removeSurface(chart.surface!) // TODO* remove overview chart surface
      chart.series?.delete()
      chart.xAxis?.visibleRangeChanged.unsubscribeAll()
      chart.xAxis?.delete()
      chart.overview?.delete()
      chart.surface?.delete()
      sciChartSurfaceRef.current = undefined
    }

    // customEvents.subscribe(customEvents.chartAttached, (evt: Event) => {
    //   const { margin, width } = (evt as CustomEvent).detail
    //   setWidth(width)
    //   setMargin(margin)
    // })

    return () => {
      // check if chart is already initialized or wait init to finish before deleting it
      sciChartSurfaceRef.current ? performCleanup() : chartInitializationPromise.then(performCleanup)
    }
  }, [])

  const overviewStyle: CSSProperties = { height: 40, width: width ? width : '100%', marginLeft: margin }

  return (
    <>
      <div id={rootElementId} className={className} style={containerStyle} />
      <div id={overviewElementId} style={overviewStyle} />
    </>
  )
}
