import { Options } from 'highcharts'
import { NoteChartOptions } from '../../../types'
import { activeThemeName } from '../../../themes'
import { getVar } from '../../../layout'

export const getNoteChartConfig = ({ fontColor, backgroundColor, onClick }: NoteChartOptions) => {
  const noteChartConfig: Options = {
    chart: {
      height: 25,
      margin: [0, 0, 0, getVar('chart-left-margin')],
      spacing: [0, 0, 0, 0],
      backgroundColor,
    },
    accessibility: {
      enabled: false,
    },
    legend: { enabled: false },
    tooltip: { enabled: false },
    time: {
      useUTC: false,
    },
    title: {
      text: undefined,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      visible: true,
      crosshair: { width: 1 },
      type: 'datetime',
      minRange: 1,
    },
    yAxis: {
      title: undefined,
      visible: false,
      labels: {
        style: { color: fontColor },
      },
    },
    series: [
      {
        type: 'scatter',
        marker: {
          symbol: `url(${activeThemeName === 'light' ? marker_black : marker_white})`,
          height: 16,
          width: 16,
        },
      },
    ],
    plotOptions: {
      series: {
        cursor: 'pointer',
        allowPointSelect: true,
        point: {
          events: {
            select: function (e: any) {
              onClick(e.target.name)
              setTimeout(() => {
                e.target?.select(false)
              })
            },
          },
        },
        states: {
          hover: {
            halo: {
              opacity: 0,
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  }
  return noteChartConfig
}

const marker_black =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAV1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD///8OKbV/AAAAG3RSTlMAAQgJGD4/QEFkZXqHj56gpaa7v+Hi7vf5+v0HSCJPAAAAAWJLR0QcnARBBwAAAHJJREFUKM+l0kkOgCAMBVCcBUWxgAPc/56GNCnBYSH+VekjpBAYy0u/AGa49J3B/uplAospsdBHKlpTMXpZPAJLhGDeAHY/3KGewgT6DtdVHgi8ongD/u0o8Qb8/7j07CGVVVR3zgLFujbualUE1WT+jxPrRQsckQ/7LwAAAABJRU5ErkJggg=='

const marker_white =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TtVUqDnYQcchQnexiRRxrFYpQIdQKrTqYXPoFTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxdXFSdJES/9cUWsR4cNyPd/ced+8AoVFhmtUTBzTdNtPJhJjNrYqBV/QhCAEx9MvMMuYkKQXP8XUPH1/vojzL+9yfY1DNWwzwicRxZpg28QbxzKZtcN4nDrOSrBKfE0+adEHiR64rLr9xLrZY4JlhM5OeJw4Ti8UuVrqYlUyNeJo4omo65QtZl1XOW5y1So2178lfGMrrK8tcpzmGJBaxBAkiFNRQRgU2orTqpFhI037Cwz/a8kvkUshVBiPHAqrQILf84H/wu1urEJtyk0IJoPfFcT7GgcAu0Kw7zvex4zRPAP8zcKV3/NUGMPtJer2jRY6AoW3g4rqjKXvA5Q4w8mTIptyS/DSFQgF4P6NvygHDt8DAmttbex+nD0CGukrdAAeHwESRstc93h3s7u3fM+3+fgBZDnKcr9nomAAAAFpQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////i/d4QwAAAAF0Uk5TAEDm2GYAAAA+SURBVCjPY2AgE8hCAS5xdCk4F10GRUIWuwQDigyqDmwS6DI4bCRbAm480RL4jCJRgjr+QI4ojBjBEYckAADRSRA0WNRwVgAAAABJRU5ErkJggg=='
