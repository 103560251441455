import { gql, useApolloClient } from '@apollo/client'

const SUBSCRIBE_DEVICE_STATUS_EVENT = gql`
  subscription DeviceStatusUpdate($patientId: String!) {
    deviceStatusUpdateEvent(patientId: $patientId) {
      id
      isConnected
    }
  }
`

export const useDeviceStatusSubscription = (patientId?: string) => {
  if (!patientId) return
  const client = useApolloClient()
  client
    .subscribe({
      query: SUBSCRIBE_DEVICE_STATUS_EVENT,
      variables: { patientId },
    })
    .subscribe(() => {
      // call subscribe here will trigger cache update, no need to handle response
    })
}
