import { FC, useCallback, useEffect, useState } from 'react'
import { Aggregate, PatientViewModel } from '../../types'
import { Link } from 'react-router-dom'
import { useMetricSettings, usePatient, usePatientFilter, useResizeObserver } from '../../hooks'
import { toMetricDisplayText, toPatientDisplayText } from '../../utils'

const itemWidth = 190

export const PatientInfoContainer: FC<{
  patients: PatientViewModel[]
  zone: number
  wardId: number
  alignment?: string
  onStatusChange: (patientId: string, newStatus: number) => void
}> = ({ patients, zone, wardId, alignment, onStatusChange }) => {
  const [columns, setColumns] = useState<number>()

  const onResize = useCallback(
    (target: HTMLDivElement) => {
      const currentWidth = target.clientWidth ?? 0
      const col = Math.round(currentWidth / itemWidth)
      setColumns(col > 8 ? 8 : col)
    },
    [zone],
  )
  const ref = useResizeObserver(onResize)

  useEffect(() => {
    const currentWidth = ref.current?.clientWidth ?? 0
    const col = Math.floor(currentWidth / itemWidth)
    setColumns(col > 8 ? 8 : col)
  }, [zone])

  const alignmentClass = alignment ? `v-flexContainer--${alignment}` : ''
  const data = alignment === 'bottom' ? patients.reverse() : patients

  return (
    <div className={`v-flexContainer ${alignmentClass} v-flexContainer--col${columns}`} ref={ref}>
      {data.map((p) => {
        return zone === 0 ? (
          <PatientTile patient={p} wardId={wardId} key={p.id} />
        ) : alignment === 'bottom' ? (
          <PatientTile patient={p} wardId={wardId} onActionClick={onStatusChange} key={p.id} />
        ) : (
          <PatientCard patient={p} wardId={wardId} key={p.id} onActionClick={onStatusChange} />
        )
      })}
    </div>
  )
}

const PatientCard: FC<{
  patient: PatientViewModel
  wardId: number
  onActionClick?: (patientId: string, newStatus: number) => void
}> = ({ patient, wardId, onActionClick }) => {
  const aggregates = patient.devices.flatMap((x) => x.aggregates) || []

  const findAggregate = (name: string): Aggregate | undefined => {
    return aggregates.find((x) => x.name === name) ?? undefined
  }

  const resp = findAggregate('respiratoryrate')
  const hr = findAggregate('heartrate')
  const sp = findAggregate('spO2')

  const handleActionClick = useCallback(() => {
    onActionClick && onActionClick(patient.id, 1)
  }, [])

  return (
    <div className="v-patientCard" key={patient.id}>
      {onActionClick ? (
        <span className="fontIcon v-hoverButton u-noSelect" onClick={handleActionClick}>
          iswActionArchive
        </span>
      ) : null}
      <Link className="v-dashboardLink" to={`/ward/${wardId}/patients/${patient.id}`} state={patient}>
        <div className="v-paddedWrapper">
          <div className="v-patientCard-header">
            <NameDisplay className="v-patientCard-label" patientId={patient.id} />
            {/* <div className="v-patientCard-score">{getDisplayValue(patient.ews)}</div> */}
          </div>
          {resp || hr || sp ? (
            <div className="v-patientCard-content">
              <DataCell data={resp} />
              <DataCell data={hr} />
              <DataCell data={sp} />
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  )
}

const PatientTile: FC<{
  patient: PatientViewModel
  wardId: number
  onActionClick?: (patientId: string, newStatus: number) => void
}> = ({ patient, wardId, onActionClick }) => {
  const aggregates = patient.devices.flatMap((d) => d.aggregates)
  const resp = aggregates.find((x) => x.name === 'respiratoryrate')
  const hr = aggregates.find((x) => x.name === 'heartrate')
  // const sp = aggregates.find((x) => x.name === 'spO2')

  const handleActionClick = useCallback(() => {
    onActionClick && onActionClick(patient.id, 0)
  }, [])

  return (
    <div className="v-patientTile">
      {onActionClick ? (
        <span className="fontIcon v-hoverButton u-noSelect" onClick={handleActionClick}>
          iswActionUnarchive
        </span>
      ) : null}
      <Link className="v-dashboardLink" to={`/ward/${wardId}/patients/${patient.id}`} state={patient}>
        <div className="v-paddedWrapper">
          <div className="v-patientTile-content">
            <NameDisplay className="v-patientTile-label" patientId={patient.id} />
            <div className="v-patientTile-stats">
              <DataCell data={resp} />
              <DataCell data={hr} />
              {/* <DataCell data={sp} /> */}
              {/* <ScoreCell data={patient.ews} /> */}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

const NameDisplay: FC<{ patientId: string; className: string }> = ({ patientId, className }) => {
  const patient = usePatient(patientId)
  return <div className={className}>{toPatientDisplayText(patient)}</div>
}

const ScoreCell: FC<{ data: string }> = ({ data }) => {
  return (
    <div className="v-patientTile-score">
      <div className="v-patientTile-scoreTitle">Score</div>
      <div className="v-patientTile-scoreValue">{toMetricDisplayText(data)}</div>
    </div>
  )
}

const DataCell: FC<{ data: Aggregate | undefined }> = ({ data }) => {
  if (!data) {
    return <div className="v-dataCell-value" />
  }

  const settings = useMetricSettings()[data.name]

  return (
    <div className="v-dataCell">
      <div className="v-dataCell-header">{settings?.abbreviation ?? data.name}</div>
      <div className="v-dataCell-value">{toMetricDisplayText(data.value, settings)}</div>
    </div>
  )
}
