import { useApolloClient } from '@apollo/client'
import { IPatientService, PatientService } from '../services'
import { useWasmSetting } from './useWasmSetting'

export const usePatientService = (patientId: string | undefined): IPatientService | undefined => {
  const client = useApolloClient()
  if (!patientId) return
  const { wasmChartEnabled } = useWasmSetting()

  return new PatientService(patientId, client, wasmChartEnabled ? 1000 : 1)
}
