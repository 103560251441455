import React, { FC } from 'react'
import { AssemblyVersion, PlatformInfo } from '../types/app'
import { gql, useQuery } from '@apollo/client'
import { GraphQLResult } from '../types/api'
import { Info } from './common/Info'
import { useFirebaseRoutesAnalytics } from '../hooks'

const QUERY_PLATFORM_INFO = gql`
  query QueryPlatformInfo {
    result: platformInfo {
      base {
        name
        version
      }
      modules {
        name
        version
      }
      deviceDrivers {
        name
        version
      }
      notificationHandlers {
        name
        version
      }
    }
  }
`

export const usePlatform = (): PlatformInfo | undefined => {
  const { loading, data, error } = useQuery<GraphQLResult<PlatformInfo>>(QUERY_PLATFORM_INFO)
  if (!loading && !error && data && data.result) {
    return data.result
  }
  if (error) {
    console.warn(error)
  }
  return undefined
}

export const PlatformPage: FC = () => {
  const platformInfo = usePlatform()
  useFirebaseRoutesAnalytics()

  if (!platformInfo) {
    return <Info text="Loading" />
  }

  return (
    <>
      <div className="v-contentArea v-contentArea--largeMargin">
        <div className="v-fixedWidthWrapper">
          <div className="v-row">
            <div className="v-contentTitle">Platform Info</div>
          </div>
          <div className="v-row--header v-contentTitle">Base</div>
          <AssemblyTable
            assemblies={[platformInfo.base, { name: 'UI', version: process.env.REACT_APP_VERSION ?? '' }]}
          />
          <div className="v-row--header v-contentTitle">Modules</div>
          <AssemblyTable assemblies={platformInfo.modules} />
          <div className="v-row--header v-contentTitle">Device Drivers</div>
          <AssemblyTable assemblies={platformInfo.deviceDrivers} />
          <div className="v-row--header v-contentTitle">Notification Handlers</div>
          <AssemblyTable assemblies={platformInfo.notificationHandlers} />
        </div>
      </div>
    </>
  )
}

const AssemblyTable: FC<{ assemblies: AssemblyVersion[] }> = ({ assemblies }) => {
  return (
    <div>
      {assemblies.map((v) => (
        <AssemblyTableRow key={v.name} asmVersion={v} />
      ))}
    </div>
  )
}

const AssemblyTableRow: FC<{ asmVersion: AssemblyVersion }> = ({ asmVersion }) => {
  const { name, version } = asmVersion

  return (
    <div className="v-row">
      <div className="v-row-item v-row-item--wide">
        <div>{name}</div>
      </div>
      <div className="v-row-item">
        <div>{version}</div>
      </div>
    </div>
  )
}
