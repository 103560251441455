import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { AppState } from '../../types'
import { DateTimeValidationError, DatePicker, PickerChangeHandlerContext, TimeField } from '@mui/x-date-pickers'
import { deleteNote, useUpsertNote } from '../../hooks'
import { differenceInMinutes, isBefore, parseISO } from 'date-fns'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { uiSlice } from '../../store'
import { useApolloClient } from '@apollo/client'
import { ConfirmModal } from './ConfirmModal'
import { customEvents } from '../../customEvents'
import { useAnalytics } from '../../hooks/useAnalytics'
import { logEvent } from 'firebase/analytics'

export const NoteModal = (): ReactElement => {
  const [text, setText] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [warning, setWarning] = useState<string>('')
  const [date, setDate] = useState<Date>(new Date())
  const upsertNote = useUpsertNote()
  const client = useApolloClient()
  const { patientId, note } = useSelector((state: AppState) => state.ui.noteModal)

  const dispatch = useDispatch()
  const analytics = useAnalytics()

  const handleSave = useCallback(() => {
    if (!patientId) return
    setIsLoading(true)

    upsertNote(patientId, text, date, note?.id)
      .then(() => {
        closeModal()
        // setTimeout(() => onRefresh(), 500)
        customEvents.publish(customEvents.calibrateTimeAxis)
      })
      .catch((error) => {
        setIsLoading(false)
        setWarning(error.message)
      })
    logEvent(analytics, 'note_activity', { action: 'add' })
  }, [patientId, text, date])

  const handleDelete = useCallback(
    (openModal: ({ message, onConfirm }: { message: string; onConfirm: () => void }) => void) => {
      if (!patientId || !note?.id) return

      openModal({
        message: 'Are you sure you want to delete this note?',
        onConfirm: () => {
          setIsLoading(true)
          deleteNote(client, patientId, note.id)
            .then(() => {
              closeModal()
            })
            .catch((error) => {
              setIsLoading(false)
              setWarning(error.message)
            })
          logEvent(analytics, 'note_activity', { action: 'delete' })
        },
      })
    },
    [patientId, text, date],
  )

  const closeModal = useCallback(() => {
    dispatch(uiSlice.actions.showNoteModal({ open: false }))
    setText('')
    setWarning('')
    setIsLoading(false)
  }, [])

  const handleChangeContent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setText(value)
  }, [])

  const handleChangeDate = useCallback(
    (value: Date | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
      if (context.validationError) {
        value = new Date()
      }
      value && setDate(value)
    },
    [],
  )

  useEffect(() => {
    if (note) {
      setText(note.text)
      setDate(parseISO(note.time))
    } else {
      setDate(new Date())
    }
  }, [patientId, note])

  return (
    <ConfirmModal>
      {(openConfirmModal) => {
        return (
          <Dialog className="v-modal" open={patientId !== undefined} onClose={closeModal}>
            <DialogTitle>{note ? 'Note' : 'Add a note'}</DialogTitle>
            <span className="fontIcon v-modal-closeButton u-noSelect" onClick={closeModal}>
              close
            </span>
            <div className="v-modal-content">
              {warning ? <div className="v-modal-warning">{warning}</div> : null}
              <DialogContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { marginBottom: 4 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label="Note content"
                    value={text}
                    multiline
                    minRows={5}
                    fullWidth
                    onChange={handleChangeContent}
                  />
                </Box>
                <DatePicker value={date} onChange={handleChangeDate} label="Date" disableFuture />
                &nbsp; &nbsp; &nbsp;
                <TimeField value={date} onChange={handleChangeDate} label="Time" disableFuture />
              </DialogContent>
            </div>
            <div className="v-modal-footer">
              <DialogActions style={{ justifyContent: 'space-between' }}>
                {note ? (
                  <Button
                    variant="contained"
                    color="error"
                    disabled={isLoading}
                    disableRipple
                    onClick={() => handleDelete(openConfirmModal)}
                  >
                    Delete
                  </Button>
                ) : (
                  <div />
                )}
                <div>
                  <Button variant="outlined" disabled={isLoading} disableRipple onClick={closeModal}>
                    Cancel
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    variant="contained"
                    disabled={
                      isLoading ||
                      text.length < 1 ||
                      (note && note.text === text && differenceInMinutes(parseISO(note.time), date) === 0) ||
                      isBefore(new Date(), date)
                    }
                    autoFocus
                    disableRipple
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </div>
          </Dialog>
        )
      }}
    </ConfirmModal>
  )
}
