import { CSSProperties, useState, useRef, useEffect } from 'react'
import {
  SciChartSurface,
  NumericAxis,
  XyDataSeries,
  XyScatterRenderableSeries,
  RolloverModifier,
  MouseWheelZoomModifier,
  EXyDirection,
  ZoomPanModifier,
  ZoomExtentsModifier,
  CursorModifier,
  generateGuid,
  SciChartOverview,
  EAxisAlignment,
  SciChartJSDarkTheme,
  ENumericFormat,
  DateTimeNumericAxis,
  DateLabelProvider,
  MemoryUsageHelper,
  Thickness,
  RubberBandXyZoomModifier,
  RolloverModifierRenderableSeriesProps,
  SeriesInfo,
  EResamplingMode,
  DpiHelper,
  EExecuteOn,
  getRubberBandRect,
  NumberRange,
  EAutoRange,
  ELabelPlacement,
  HorizontalLineAnnotation,
  EAnnotationLayer,
  BoxAnnotation,
  ECoordinateMode,
  ELabelAlignment,
  EllipsePointMarker,
  createImageAsync,
  SpritePointMarker,
} from 'scichart'
import { SmartDateLabelProvider } from 'scichart/Charting/Visuals/Axis/LabelProvider/SmartDateLabelProvider'
import { customEvents } from '../../customEvents'
import json from '../../assets/color.json'
import { activeThemeName, getColor } from '../../themes'
import { TooltipModifier } from '../scichart/TooltipModifier'
import { MetricSettings } from '../../types'
import { toHexColor } from '../../utils'
import { chartGroup } from './SciChartGroup'
import { right } from '@popperjs/core'
const color = json.color.base
import markerImage from '../../assets/24-document.svg'
import markerImageWhite from '../../assets/24-document-white.svg'
import { SciChartAdaptor } from '.'

SciChartSurface.useWasmFromCDN()
SciChartSurface.autoDisposeWasmContext = true
SciChartSurface.UseCommunityLicense()
// MemoryUsageHelper.isMemoryUsageDebugEnabled = true

const createChart = async (
  divElementId: string,
  style?: any,
  options?: MetricSettings,
  onClick?: (value: number) => void,
): Promise<SciChartAdaptor> => {
  const theme = { ...new SciChartJSDarkTheme() }
  // const theme: any = {}

  theme.sciChartBackground = 'transparent' // getColor('--chart-bg-color')
  const { sciChartSurface, wasmContext } = await SciChartSurface.create(divElementId, {
    theme,
  })

  chartGroup.addSurfaceToGroup(sciChartSurface)

  // const xAxis = new CategoryAxis(wasmContext, { labelProvider: new SmartDateLabelProvider() })
  const xAxis = new DateTimeNumericAxis(wasmContext, {
    labelProvider: new SmartDateLabelProvider({ showWiderDateOnFirstLabel: true }),
    labelStyle: { fontSize: 12 },
    // labelProvider: new DateLabelProvider({ labelFormat: ENumericFormat.Date_DDMMYYYY }),
  })

  // xAxis.visibleRangeChanged.subscribe((arg) => {
  //   if (!arg) return
  //   const SECONDS_IN_DAY = 86400
  //   const SECONDS_IN_HOUR = 3600
  //   console.log('format')
  //   if (arg.visibleRange.max - arg.visibleRange.min < SECONDS_IN_HOUR) {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_HHMMSS
  //   } else if (arg.visibleRange.max - arg.visibleRange.min < SECONDS_IN_DAY) {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_HHMM
  //   } else {
  //     xAxis.labelProvider.numericFormat = ENumericFormat.Date_DDMMYYYY
  //   }
  // })

  // xAxis.visibleRangeChanged.subscribe((arg) => {
  //   if (!arg) return
  //   // console.log(`visibleRange: ${arg.visibleRange.min}-${arg.visibleRange.max}`)
  // })

  xAxis.drawMajorGridLines = false
  xAxis.drawMinorGridLines = false
  xAxis.drawMajorTickLines = false
  xAxis.drawMinorTickLines = false
  xAxis.drawMajorBands = false
  // xAxis.labelStyle.padding = new Thickness(0, 0, 2, 0)

  const yAxis = new NumericAxis(wasmContext, {
    axisAlignment: EAxisAlignment.Left,
    labelStyle: { fontSize: 12, padding: Thickness.fromString('0 0 0 0') },
    maxAutoTicks: options?.zones ? options?.zones.length : 5,
    axisTitleStyle: { fontSize: 14, padding: Thickness.fromString('0 0 0 0') },
  })
  yAxis.drawMajorGridLines = false
  yAxis.drawMinorGridLines = false
  yAxis.drawMajorBands = false
  yAxis.drawMajorTickLines = false
  yAxis.drawMinorTickLines = false

  // yAxis.labelStyle.padding = new Thickness(0, 10, 0, 10)
  if (options) {
    if (options.min !== undefined && options.max !== undefined) {
      yAxis.autoRange = EAutoRange.Never
      yAxis.visibleRange = new NumberRange(options.min, options.max)
    }

    if (options.hideValue) {
      yAxis.drawLabels = false
    }

    const labelProvider = yAxis.labelProvider
    if (options.roundUpDigits !== undefined) {
      labelProvider.numericFormat = ENumericFormat.Decimal
      labelProvider.cursorNumericFormat = ENumericFormat.Decimal
      labelProvider.precision = options.roundUpDigits
      const n = 0
      yAxis.textFormatting = n.toFixed(options.roundUpDigits)
    }

    const categories = options.categories
    if (categories) {
      labelProvider.formatLabel = (value: number) => {
        console.log(value)
        return categories[value]
      }
    }

    if (!options.hideAxisTitle && options.displayName) {
      yAxis.axisTitle = options?.displayName
    }
  }

  // const onAttach = yAxis.onAttach.bind(yAxis)
  // yAxis.onAttach = (parentSurface: SciChartSurface, isXAxis: boolean, isPrimaryAxis: boolean) => {
  //   onAttach(parentSurface, isXAxis, isPrimaryAxis)
  //   setTimeout(() => {
  //     const canvas = sciChartSurface.domCanvas2D
  //     const svg = sciChartSurface.domSvgContainer
  //     if (canvas && svg) {
  //       // const margin = canvas.clientWidth - svg.clientWidth
  //       // console.log(canvas.clientWidth + ' / ' + svg.clientWidth)

  //       const margin = (yAxis.viewRect.x + yAxis.viewRect.width) / DpiHelper.PIXEL_RATIO
  //       console.log(margin)
  //       const event = new CustomEvent(customEvents.chartAttached, {
  //         detail: {
  //           margin,
  //         },
  //       })
  //       customEvents.publish(event)
  //     }
  //   })
  // }

  if (style) {
    if (!style.showXAxis) {
      xAxis.drawLabels = false
    }
    if (!style.showYAxis) {
      yAxis.drawLabels = false
      // yAxis.isVisible = false
    }
    if (style.padding) sciChartSurface.padding = Thickness.fromString(style.padding)
  }

  sciChartSurface.xAxes.add(xAxis)
  sciChartSurface.yAxes.add(yAxis)

  const xyDataSeries = new XyDataSeries(wasmContext)

  const imageBitmap = await createImageAsync(activeThemeName === 'light' ? markerImage : markerImageWhite)
  const series = new XyScatterRenderableSeries(wasmContext, {
    dataSeries: xyDataSeries,
    pointMarker: new SpritePointMarker(wasmContext, {
      width: 30,
      height: 30,
      image: imageBitmap,
    }),
  })
  series.strokeThickness = 1
  series.stroke = 'rgba(255,255,255,0.7)'
  // lineSeries.rolloverModifierProps.tooltipColor = 'blue'
  series.rolloverModifierProps.tooltipColor = 'transparent'
  series.rolloverModifierProps.tooltipTextColor = getColor('--pri-ft-color')
  series.rolloverModifierProps.markerColor = 'transparent'

  const HIT_TEST_RADIUS = 10 * DpiHelper.PIXEL_RATIO

  sciChartSurface.domCanvas2D.addEventListener('click', (mouseEvent) => {
    const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO
    const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO
    const hitTestInfo = series.hitTestProvider.hitTestDataPoint(premultipliedX, premultipliedY, HIT_TEST_RADIUS)
    hitTestInfo.isHit && onClick && onClick(hitTestInfo.xValue)
  })

  sciChartSurface.renderableSeries.add(series)

  const rolloverModifier = new RolloverModifier({
    modifierGroup: 'group1',
    // snapToDataPoint: true,
    showTooltip: false,
    rolloverLineStroke: getColor('--pri-ft-color'),
    rolloverLineStrokeThickness: 1,
  })

  const tooltipModifier = new TooltipModifier({
    modifierGroup: 'group1',
    showRolloverLine: true,
    showTooltip: true,
    // placementDivId: divElementId,
    rolloverLineStroke: getColor('--pri-ft-color'),
    rolloverLineStrokeThickness: 1,
    tooltipDataTemplate: (
      seriesInfo: SeriesInfo,
      tooltipTitle: string,
      tooltipLabelX: string,
      tooltipLabelY: string,
    ) => {
      // Lines here are returned to the tooltip and displayed as text-line per tooltip
      const lines: string[] = []
      let value: any = seriesInfo.yValue
      if (options?.categories) {
        value = options.categories[value]
      }
      lines.push(`${value}`)
      lines.push(`${seriesInfo.formattedXValue}`)
      return lines
    },
  })

  const rubberBandZoomModifier = new RubberBandXyZoomModifier({
    modifierGroup: 'group1',
    executeOn: EExecuteOn.MouseLeftButton,
  })
  rubberBandZoomModifier.xyDirection = EXyDirection.XDirection
  const mouseUp = rubberBandZoomModifier.modifierMouseUp.bind(rubberBandZoomModifier)

  rubberBandZoomModifier.modifierMouseUp = (args) => {
    mouseUp(args)
    const modifier: any = rubberBandZoomModifier
    if (args.button !== EExecuteOn.MouseLeftButton) return
    const rect = getRubberBandRect(
      modifier.pointFrom,
      modifier.pointTo,
      EXyDirection.XDirection,
      modifier.parentSurface.seriesViewRect,
    )
    if (Math.abs(rect.x - rect.right) < 1) return
    console.log(rect.x - rect.right)
    const coordCalc = xAxis.getCurrentCoordinateCalculator()

    console.log(`rect ${divElementId}: ${coordCalc.getDataValue(rect.x)}-${coordCalc.getDataValue(rect.right)}`)

    customEvents.publish(
      customEvents.onZoom,
      new NumberRange(coordCalc.getDataValue(rect.x), coordCalc.getDataValue(rect.right)),
    )
  }

  const zoomPanModifier = new ZoomPanModifier({
    modifierGroup: 'group1',
    xyDirection: EXyDirection.XDirection,
    executeOn: EExecuteOn.MouseRightButton,
  })

  const zoomExtentsModifier = new ZoomExtentsModifier({ modifierGroup: 'group1', xyDirection: EXyDirection.XDirection })
  const cursorModifier = new CursorModifier({
    showTooltip: false,
    showAxisLabels: false,
    showXLine: false,
    showYLine: false,
  })

  sciChartSurface.chartModifiers.add(
    rolloverModifier,
    // tooltipModifier,
    zoomExtentsModifier,
    rubberBandZoomModifier,
    zoomPanModifier,
    // cursorModifier,
  )

  customEvents.subscribe(customEvents.onVisibleRangeChanged, (e) => {
    const { data, source } = (e as CustomEvent).detail
    if (source === divElementId) return
    requestAnimationFrame(() => {
      xAxis.visibleRange = data.visibleRange
    })
  })

  // zoom to fit
  sciChartSurface.zoomExtentsX()

  return {
    surface: sciChartSurface,
    series,
    xAxis,
    addSeries: () => {
      const xyDataSeries = new XyDataSeries(wasmContext)
      const s = new XyScatterRenderableSeries(wasmContext)
      s.strokeThickness = 1
      s.stroke = color.green[60].value
      s.dataSeries = xyDataSeries
      sciChartSurface.renderableSeries.add(s)
      return s
    },
  }
}

export function SciScatterChart({
  chartRef,
  className,
  containerStyle,
  style,
  showOverview,
  options,
  onLoad,
  onClick,
}: {
  chartRef?: React.MutableRefObject<SciChartAdaptor | undefined>
  className?: string
  containerStyle?: CSSProperties
  style?: any
  showOverview?: boolean
  options?: MetricSettings
  onLoad?: (chart: SciChartAdaptor) => void
  onClick?: (value: number) => void
}) {
  const [rootElementId] = useState(`chart-root-${generateGuid()}`)
  const sciChartSurfaceRef = useRef<SciChartAdaptor>()

  useEffect(() => {
    const chartInitializationPromise = createChart(rootElementId, style, options, onClick)

    chartInitializationPromise.then((initResult) => {
      if (!initResult) return
      sciChartSurfaceRef.current = initResult
      if (chartRef) chartRef.current = initResult
      onLoad && onLoad(initResult)
      return initResult
    })

    const performCleanup = () => {
      const chart = sciChartSurfaceRef.current
      if (!chart) return
      chartGroup.removeSurface(chart.surface!)
      chart.series?.delete()
      chart.xAxis?.visibleRangeChanged.unsubscribeAll()
      chart.xAxis?.delete()
      chart.overview?.delete()
      chart.surface?.delete()
      sciChartSurfaceRef.current = undefined
    }

    return () => {
      // check if chart is already initialized or wait init to finish before deleting it
      sciChartSurfaceRef.current ? performCleanup() : chartInitializationPromise.then(performCleanup)
    }
  }, [])

  return (
    <>
      <div id={rootElementId} className={className} style={containerStyle} />
    </>
  )
}
