import { gql, useApolloClient } from '@apollo/client'
import { ActivePatient, ActivePatientInfo, GraphQLResult, Patient } from '../types'
import { uiStateService, updatePatientStatus } from '../services'
import { useBatchQuery } from './apolloHelper'

export const QUERY_ACTIVE_PATIENT_AND_DETAILS = gql`
  query QueryActivePatientAndDetails($patientId: ID!) {
    activePatient(id: $patientId) {
      id
      status
      devices {
        id
        aggregates {
          name
          value
        }
      }
    }
    patient(id: $patientId) {
      id
      firstName
      lastName
      reference
    }
  }
`

type PatientDetailQueryResult = {
  activePatient: ActivePatient
  patient: Patient
}

export const useActivePatientList = (patientIds: string[]): ActivePatientInfo[] => {
  const params = patientIds.map((p) => ({ patientId: p }))
  const client = useApolloClient()

  const isChanged = (oldData: PatientDetailQueryResult, newData: PatientDetailQueryResult) => {
    if (oldData === newData) return false
    const { activePatient } = newData
    const cachedStatus = uiStateService.loadPatientStatus(activePatient.id)
    if (activePatient.status !== cachedStatus) {
      updatePatientStatus(client.cache, activePatient.id, cachedStatus)
    }
    return true
  }

  const result = useBatchQuery<GraphQLResult<PatientDetailQueryResult>>(
    QUERY_ACTIVE_PATIENT_AND_DETAILS,
    params,
    (param) => '' + param.patientId,
    (oldVal, newVal) => {
      return oldVal.result && newVal.result ? isChanged(oldVal.result, newVal.result) : true
    },
  )

  return result.filter((item) => item).map((item: any) => ({ ...item.activePatient, ...item.patient }))
}
