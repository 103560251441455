import { lazy } from 'react'

export * from './LocalDateLabelProvider'
export * from './SciChart'
export * from './SciChartGroup'
export * from './SciScatterChart'
export * from './SciOverview'
export * from './TooltipModifier'

export const SciChartPane = lazy(() => import('./SciChartPane'))
export const SciNavigator = lazy(() => import('./SciNavigator'))
export const SciNotePanel = lazy(() => import('./SciNotePanel'))
export const SciTimeAxis = lazy(() => import('./SciTimeAxis'))
