import json from './assets/color.json'
import { ColorStyle } from './types'

const base = json.color.base

const palette: string[] = [
  base.green[50].value,
  base.blue[40].value,
  base.orange[40].value,
  base.isw.mutedPurple.value,
  base.yellow[70].value,
  base.red[70].value,
]

const map = new Map<string, string>()
let index = 0

export const getPalette = (id: string): string => {
  let value = map.get(id)
  if (!value) {
    value = palette[index]
    map.set(id, value)
    index++
    if (index >= palette.length) {
      index = 0
    }
  }

  return value
}

export const getColorStyle = (id: string): ColorStyle => {
  return { color: getPalette(id) }
}
