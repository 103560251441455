import { FetchResult, gql, useApolloClient, ApolloCache, ApolloClient } from '@apollo/client'
import { useEffect } from 'react'
import {
  Aggregate,
  ActiveDevice,
  ActivePatientEvent,
  PatientActivateEvent,
  PatientAttachEvent,
  PatientUpdateEvent,
  GraphQLResult,
} from '../types'
import { updatePatientStatus } from '../services'

export const SUBSCRIBE_WARD_ACTIVE_PATIENT_EVENT = gql`
  subscription WardActivePatientsEvent($wardId: Int!) {
    result: wardActivePatientsEvents(wardId: $wardId) {
      __typename
      ... on PatientActivateEvent {
        patientId
        isActive
      }
      ... on PatientAttachEvent {
        deviceId
        fromPatient
        toPatient
      }
      ... on PatientUpdateEvent {
        patientId
        deviceId
        updated
        deviceUpdate {
          key
          value
        }
      }
    }
  }
`

const updateWardActivePatientIds = (activePatientIds: string[], evt: PatientActivateEvent) => {
  const { isActive, patientId } = evt
  if (isActive) {
    if (!activePatientIds.includes(patientId)) {
      return activePatientIds.concat(patientId)
    }
  } else {
    const at = activePatientIds.indexOf(patientId)
    if (at >= 0) {
      return activePatientIds.filter((_value, index) => index !== at)
    }
  }
  return activePatientIds
}

const processPatientAttachEvent = (cache: ApolloCache<object>, evt: PatientAttachEvent) => {
  const { deviceId, fromPatient, toPatient } = evt
  if (fromPatient) {
    cache.modify({
      id: 'ActivePatient:' + fromPatient,
      fields: {
        devices: (devices) => {
          return devices.filter((value: ActiveDevice) => {
            if (value.id === deviceId) {
              return false
            }
            return true
          })
        },
      },
    })
  }
  if (toPatient) {
    cache.modify({
      id: 'ActivePatient:' + toPatient,
      fields: {
        devices: (devices) => {
          if (devices.find((device: ActiveDevice) => device.id === deviceId)) {
            return devices
          }
          return devices.concat({
            id: deviceId,
            aggregates: [],
          } as ActiveDevice)
        },
      },
    })
  }
}

const updateActivePatientAggregates = (
  devices: ActiveDevice[],
  evt: PatientUpdateEvent,
  updatePatientStatus: (patientId: string, newStatus: number) => void,
) => {
  const { patientId, deviceId, deviceUpdate, updated } = evt
  devices = devices.slice(0)
  const cacheIndex = devices.findIndex((device) => device.id === deviceId)
  if (cacheIndex < 0) {
    return devices
  }
  const oldDevices = devices[cacheIndex]
  const device = { ...oldDevices }
  devices[cacheIndex] = device
  const updatedAggregates = deviceUpdate.map<Aggregate>(({ key, value }) => ({ name: key, value, updated }))
  device.aggregates = oldDevices.aggregates
    .map((oldAggregate) => {
      const update = deviceUpdate.find((update) => oldAggregate.name === update.key)
      const result = update ? { name: update.key, value: update.value, updated } : oldAggregate
      notifyEwsZoneChange(result, oldAggregate, patientId, updatePatientStatus)
      return result
    })
    .concat(
      updatedAggregates.filter((aggregate) => !oldDevices.aggregates.find((value) => value.name === aggregate.name)),
    )
  return devices
}

const notifyEwsZoneChange = (
  newData: Aggregate,
  old: Aggregate,
  patientId: string,
  updatePatientStatus: (patientId: string, newStatus: number) => void,
) => {
  if (newData.name !== 'ews') return
  if (Math.ceil(parseFloat(newData.value) / 10) - Math.ceil(parseFloat(old.value) / 10) <= 0) return
  // Patient moved up EWS category - move card to top
  updatePatientStatus(patientId, 0)
}

const onWardEvent = (
  wardId: string,
  cache: ApolloCache<object>,
  result: FetchResult<GraphQLResult<ActivePatientEvent>>,
): void => {
  if (!result.data) {
    return
  }
  const evt = result.data.result
  if (!evt) {
    return
  }
  const { __typename } = evt
  if (__typename === 'PatientActivateEvent') {
    const event = evt as PatientActivateEvent
    if (!event.isActive)
    {
      cache.evict({ id: 'ActivePatient:' + event.patientId })
    }
    cache.modify({
      id: 'Ward:' + wardId,
      fields: {
        activePatientIds: (patientIds) => updateWardActivePatientIds(patientIds, event),
      },
    })
  } else if (__typename === 'PatientAttachEvent') {
    processPatientAttachEvent(cache, evt as PatientAttachEvent)
  } else {
    // __typename === 'PatientUpdateEvent'
    const event = evt as PatientUpdateEvent
    cache.modify({
      id: 'ActivePatient:' + event.patientId,
      fields: {
        devices: (devices) =>
          updateActivePatientAggregates(devices, event, (patientId: string, newStatus: number) => {
            updatePatientStatus(cache, patientId, newStatus)
          }),
      },
    })
  }
}

export const useWardSubscription = (wardId: number) => {
  const client = useApolloClient()
  useEffect(() => {
    const observable = client.subscribe<GraphQLResult<ActivePatientEvent>>({
      query: SUBSCRIBE_WARD_ACTIVE_PATIENT_EVENT,
      variables: { wardId },
    })
    const subscription = observable.subscribe((next) => onWardEvent('' + wardId, client.cache, next))
    return () => {
      subscription.unsubscribe()
    }
  }, [wardId])
}

// Todo* below is for testing with mock data, remove when finished
const testPatientId = 'd0a289ca-577e-4364-ac97-a80c42c014b6'

export const removeDevice = (deviceId: string, client: ApolloClient<object>) => {
  onWardEvent('0', client.cache, {
    data: {
      result: {
        __typename: 'PatientAttachEvent',
        fromPatient: testPatientId,
        toPatient: null,
        deviceId,
      },
    },
  })
}

let counter = 30
export const addMetric = (key: string, client: ApolloClient<object>) => {
  onWardEvent('0', client.cache, incrementMetric(key, counter++))
}

export const addDevice = (device: string, metric: string, client: ApolloClient<object>) => {
  onWardEvent('0', client.cache, createNewMetric(device, metric, counter++))
}

const createNewMetric = (device: string, key: string, value: number) => ({
  data: {
    result: {
      __typename: 'PatientUpdateEvent',
      patientId: testPatientId,
      deviceId: device,
      updated: '',
      deviceUpdate: [{ key: key, value: '' + value, __typename: 'KeyValuePairOfStringAndString' }],
    },
  },
})

const incrementMetric = (key: string, value: number) => ({
  data: {
    result: {
      __typename: 'PatientUpdateEvent',
      patientId: testPatientId,
      updated: '',
      deviceId: 'Fake_HR_201',
      deviceUpdate: [{ key: key, value: '' + value, __typename: 'KeyValuePairOfStringAndString' }],
    },
  },
})
