import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { AppState, DataRange, DateRangeTimestamp, Patient } from '../../types'
import { DatePicker } from '@mui/x-date-pickers'
import { subDays, addDays, startOfDay, endOfDay } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { uiSlice } from '../../store'
import { useAnalytics } from '../../hooks/useAnalytics'
import { logEvent } from 'firebase/analytics'

const defaultRange = 13
export const DatePickerModal = ({ patient }: { patient: Patient }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dataRange = useSelector<AppState, DataRange>((state) => state.ui.dataRange)
  const [start, setStart] = useState<Date>(subDays(new Date(), defaultRange))
  const [end, setEnd] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState(false)
  const [warning, setWarningState] = useState<string>('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isOpen) return
  }, [isOpen])

  const analytics = useAnalytics()

  const openModal = useCallback(() => {
    dataRange.value.start && setStart(new Date(dataRange.value.start))
    setEnd(dataRange.value.end ? new Date(dataRange.value.end) : new Date())
    setIsOpen(true)
  }, [dataRange])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setWarning('')
  }, [])

  const handleConfirm = useCallback(() => {
    setWarning('')
    setIsOpen(false)
    setTimeout(() =>
      dispatch(
        uiSlice.actions.setDataRange({ value: { start: startOfDay(start).getTime(), end: endOfDay(end).getTime() } }),
      ),
    )
    logEvent(analytics, 'range_modal_select', {
      start: start,
      end: end,
    })
  }, [start, end])

  const handleChangeStart = useCallback(
    (value: Date | null) => {
      if (!value || (patient.firstSessionDate && value < startOfDay(patient.firstSessionDate))) {
        return
      }

      setStart(value)
      let newEnd = addDays(value, defaultRange)
      if (new Date() < newEnd) {
        newEnd = new Date()
      }
      if (newEnd !== end) {
        setEnd(newEnd)
      }
      setWarning('')
    },
    [start, end],
  )

  const handleChangeEnd = useCallback(
    (value: Date | null) => {
      if (!value) {
        return
      }
      setEnd(value)
      let newStart = subDays(value, defaultRange)
      if (newStart < minLimit) {
        newStart = minLimit
      }
      if (newStart !== start) {
        setStart(newStart)
      }
      setWarning('')
    },
    [start, end],
  )

  const setWarning = useCallback((message: string) => {
    setWarningState(message)
  }, [])

  const minLimit = patient.firstSessionDate ? patient.firstSessionDate : subDays(end, defaultRange)
  return (
    <>
      <div className="v-iconButton v-layout--vertical" onClick={openModal}>
        <span className="fontIcon">calendar</span>
      </div>
      <Dialog className="v-modal" open={isOpen} onClose={closeModal}>
        <DialogTitle>Select Data Range</DialogTitle>
        <span className="fontIcon v-modal-closeButton" onClick={closeModal}>
          close
        </span>
        <div className="v-modal-content">{warning ? <div className="v-modal-warning">{warning}</div> : null}</div>
        <div className="v-modal-content">
          <DialogContent>
            <div className="v-modal-datepicker">
              <DatePicker
                label="Start Date"
                value={start}
                minDate={minLimit}
                maxDate={subDays(new Date(), defaultRange)}
                onAccept={handleChangeStart}
              />
              &nbsp; &nbsp; &nbsp;
              <DatePicker
                label="End Date"
                value={end}
                minDate={addDays(minLimit, defaultRange)}
                maxDate={new Date()}
                onAccept={handleChangeEnd}
              />
            </div>
          </DialogContent>
        </div>
        <div className="v-modal-footer">
          <DialogActions>
            <Button variant="outlined" disabled={isLoading} disableRipple onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="contained" disabled={isLoading} onClick={handleConfirm} autoFocus disableRipple>
              Ok
              {isLoading ? <span style={{ marginLeft: 8 }} className="v-spinner--small" /> : null}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}
