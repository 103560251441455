import { ApolloCache } from '@apollo/client'
import { uiStateService } from './UIStateService'

export const updatePatientStatus = (cache: ApolloCache<object>, patientId: string, newStatus: number) => {
  uiStateService.savePatientStatus(patientId, newStatus)
  cache.modify({
    id: 'ActivePatient:' + patientId,
    fields: {
      status: () => newStatus,
    },
  })
}
