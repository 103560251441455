import React, { FC, ReactElement, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import { Info } from '../components'
import { TokenContext } from '.'
import { useAnalytics } from '../hooks/useAnalytics'
import { logEvent, setUserId } from 'firebase/analytics'
import * as Sentry from '@sentry/react'

export const AuthHandler: FC<{ children: ReactElement }> = ({ children }) => {
  const { isLoading, isAuthenticated, error, loginWithRedirect, logout, getAccessTokenSilently, user } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()
  const analytics = useAnalytics()

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((value) => {
          if (value) {
            const token = jwt_decode<{ privileges: string[]; sub: string }>(value)
            if (token.privileges?.includes('allow:login')) {
              logEvent(analytics, 'login')
              setAccessToken(value)
            } else {
              setAccessToken('')
            }
          }
        })
        .catch((ex) => {
          if (ex.message && ex.message.includes('refresh token')) {
            logout()
          }
          setAccessToken('')
        })
    }
  }, [isAuthenticated])

  if (isLoading) {
    return <Info text="Connecting" />
  }

  if (error) {
    return <Info text={`Error: ${error.message}`} />
  }

  if (!isAuthenticated) {
    loginWithRedirect()
    return <></>
  }

  if (accessToken === '') {
    return <LogoutView logout={logout} />
  }

  if (accessToken === undefined) {
    return <Info text="Loading ..." />
  }

  const token = jwt_decode<{ privileges: string[]; sub: string }>(accessToken)
  setUserId(analytics, token.sub)

  return <TokenContext.Provider value={accessToken}>{children}</TokenContext.Provider>
}

const LogoutView = ({ logout }: { logout: (options?: LogoutOptions | undefined) => Promise<void> }) => {
  return (
    <div className="v-loadingPage">
      Access denied <span style={{ padding: '0 1em' }}>|</span>
      <span
        style={{ color: '#59b4dc', fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      >
        Log out
      </span>
    </div>
  )
}
