/* eslint-disable @typescript-eslint/no-this-alias */
import {
  BaseRenderableSeries,
  ChartModifierBase,
  ChartModifierBase2D,
  DpiHelper,
  EBaseType,
  EChart2DModifierType,
  ECoord,
  ECoordinateMode,
  EModifierType,
  EMousePosition,
  ESeriesType,
  EShift,
  ESize,
  IRenderableSeries,
  IRolloverModifierOptions,
  LineAnnotation,
  ModifierMouseArgs,
  RolloverMarkerSvgAnnotation,
  RolloverModifierRenderableSeriesProps,
  RolloverTooltipSvgAnnotation,
  SciChartSurface,
  SciChartSurfaceBase,
  SeriesInfo,
  TRolloverTooltipDataTemplate,
  getFunction,
  translateFromCanvasToSeriesViewRect,
  translateToNotScaled,
} from 'scichart'
import { checkHasOverlap, spreadTooltips } from 'scichart/utils/tooltip'
import { getColor } from '../../themes'
const TOOLTIP_SPACING = 4

export class TooltipModifier extends ChartModifierBase2D {
  type: string = EChart2DModifierType.Rollover
  mousePosition: EMousePosition = EMousePosition.OutOfCanvas
  includedSeriesMap = new Map()
  hitTestRadius = 0
  absoluteXCoord = 0
  placementDivIdProperty = ''
  showTooltip = true
  tooltipDataTemplate: TRolloverTooltipDataTemplate = (
    seriesInfo: SeriesInfo,
    tooltipTitle: string,
    tooltipLabelX: string,
    tooltipLabelY: string,
  ) => {
    // Lines here are returned to the tooltip and displayed as text-line per tooltip
    const lines: string[] = []
    // lines.push(tooltipTitle)
    lines.push(`${seriesInfo.yValue}`)
    lines.push(`${seriesInfo.formattedXValue}`)
    return lines
  }

  rolloverLineAnnotation: LineAnnotation
  tooltipDataTemplateProperty: any
  showRolloverLine: any = true
  showRolloverLineProperty: any = true
  snapToDataPoint: any = false

  constructor(options?: IRolloverModifierOptions) {
    super(options)
    if (options?.placementDivId) this.placementDivIdProperty = options.placementDivId

    this.rolloverLineAnnotation = this.createLine(options)
    if (options?.tooltipDataTemplate) {
      const t: any = options.tooltipDataTemplate
      this.tooltipDataTemplate = t
    }
  }

  modifierMouseMove(args: ModifierMouseArgs): void {
    this.activePointerEvents.set(args.pointerId, args)
    super.modifierMouseMove.call(this, args)
    let translatedMousePoint
    if (!this.mousePoint) {
      this.mousePosition = EMousePosition.OutOfCanvas
    } else {
      translatedMousePoint = translateFromCanvasToSeriesViewRect(this.mousePoint, this.parentSurface.seriesViewRect)
      if (!translatedMousePoint) {
        this.mousePosition = EMousePosition.AxisArea
      } else {
        this.mousePosition = EMousePosition.SeriesArea
      }
    }
    const isActionAllowed = this.getIsActionAllowed(args)
    if (isActionAllowed) {
      this.update()
    }
  }

  getIncludedRenderableSeries = () => {
    const _this = this
    const regularSeries = this.parentSurface.renderableSeries.asArray().filter(function (rs) {
      return !rs.isStacked && rs.isVisible && rs.rolloverModifierProps.showRollover && _this.testIsIncludedSeries(rs)
    })
    const stackedSeries = this.parentSurface.renderableSeries.asArray().filter(function (rs) {
      return rs.isStacked
    })
    const result = regularSeries
    stackedSeries.forEach(function (rs: any) {
      rs.getVisibleSeries().forEach(function (childRs: any) {
        if (childRs.rolloverModifierProps.showRollover && _this.testIsIncludedSeries(childRs)) {
          result.push(childRs)
        }
      })
    })
    return result
  }

  testIsIncludedSeries(series: any) {
    return this.includedSeriesMap.get(series) !== false
  }

  update() {
    this.updateLine()
    this.updateSeriesAnnotations()
    // if (this.tooltipLegendTemplate) {
    //   this.legendAnnotation.seriesInfos = this.getSeriesInfos()
    // }
  }

  updateSeriesAnnotations() {
    const _this = this
    const rsList = this.getIncludedRenderableSeries()
    rsList.forEach(function (rs) {
      const props = _this.getRolloverProps(rs)
      if (!props.marker) {
        _this.addSeriesAnnotationsToParentSurface(rs)
      }
      props.marker.suspendInvalidate()
      props.tooltip.suspendInvalidate()
      props.marker.isHidden = true
      props.tooltip.isHidden = true
      props.tooltip.x1 = 0
      props.tooltip.y1 = 0
    })
    if (this.mousePosition !== EMousePosition.SeriesArea) {
      rsList.forEach(function (rs) {
        const props = _this.getRolloverProps(rs)
        props.marker.resumeInvalidate()
        props.tooltip.resumeInvalidate()
      })
      return
    }
    const tooltipArray: any[] = []
    const height = this.parentSurface.seriesViewRect.height
    rsList.forEach(function (rs, index) {
      const hitTestInfo = _this.hitTestRenderableSeries(rs, _this.mousePoint)
      if (hitTestInfo) {
        if ((rs.type !== ESeriesType.StackedColumnSeries && _this.hitTestRadius === 0) || hitTestInfo.isHit) {
          const isVisible = 0 <= hitTestInfo.yCoord && hitTestInfo.yCoord <= height
          if (isVisible) {
            _this.absoluteXCoord = hitTestInfo.xCoord
            const absoluteYCoord = hitTestInfo.yCoord
            const tooltipProps = calcTooltipProps(
              index,
              rs,
              _this.getRolloverProps(rs),
              _this.parentSurface.seriesViewRect,
              hitTestInfo.xValue,
              hitTestInfo.yValue,
              _this.absoluteXCoord,
              absoluteYCoord,
              hitTestInfo,
              DpiHelper.PIXEL_RATIO,
              false,
              false,
            )
            if (tooltipProps) tooltipArray.push(tooltipProps)
          }
        }
      }
    })
    const orderedTooltipArray = tooltipArray.sort(function (a, b) {
      return a.yCoord > b.yCoord ? 1 : b.yCoord > a.yCoord ? -1 : 0
    })

    const tooltipPositions = this.CalculateTooltipPositions(
      orderedTooltipArray,
      true,
      TOOLTIP_SPACING * DpiHelper.PIXEL_RATIO,
      this.parentSurface.seriesViewRect,
      DpiHelper.PIXEL_RATIO,
    )
    tooltipPositions.forEach((el: any) => {
      const rs = rsList[el.index]
      const showTooltip = _this.showTooltip && el.seriesInfo.isHit
      const showMarker = el.seriesInfo.isHit
      // if (el.isY1) {
      //   exports.updateRolloverModifierProps(
      //     _this.getRolloverProps1(rs),
      //     rs,
      //     el,
      //     showTooltip,
      //     showMarker,
      //     _this.placementDivId,
      //   )
      // } else {
      updateRolloverModifierProps(
        _this.getRolloverProps(rs),
        rs,
        el,
        showTooltip,
        showMarker,
        _this.placementDivIdProperty,
        height,
      )
      // }
    })
    rsList.forEach((rs: any) => {
      _this.getRolloverProps(rs).marker.resumeInvalidate()
      _this.getRolloverProps(rs).tooltip.resumeInvalidate()
    })
  }

  updateLine() {
    if (this.mousePosition !== EMousePosition.SeriesArea) {
      this.rolloverLineAnnotation.isHidden = true
      return
    }
    if (!this.showRolloverLineProperty) {
      this.rolloverLineAnnotation.isHidden = true
      return
    }
    if (this.snapToDataPoint) {
      const firstSeries = this.getIncludedRenderableSeries()[0]
      if (firstSeries) {
        const hitTestInfo = this.hitTestRenderableSeries(firstSeries, this.mousePoint)
        if (hitTestInfo && hitTestInfo.isWithinDataBounds) {
          this.rolloverLineAnnotation.isHidden = false
          const x = translateToNotScaled(hitTestInfo.xCoord)
          this.rolloverLineAnnotation.x1 = x
          this.rolloverLineAnnotation.x2 = x
          this.rolloverLineAnnotation.y1 = 0
          this.rolloverLineAnnotation.y2 = translateToNotScaled(this.parentSurface.seriesViewRect.bottom)
        } else {
          this.rolloverLineAnnotation.isHidden = true
        }
      } else {
        this.rolloverLineAnnotation.isHidden = true
      }
    } else {
      this.rolloverLineAnnotation.isHidden = false
      const mousePoint: any = this.mousePoint
      const translatedMousePoint = translateFromCanvasToSeriesViewRect(mousePoint, this.parentSurface.seriesViewRect)
      if (translatedMousePoint) {
        const x = translateToNotScaled(translatedMousePoint.x)
        const y = translateToNotScaled(translatedMousePoint.y)

        this.rolloverLineAnnotation.x1 = x
        this.rolloverLineAnnotation.x2 = x
        this.rolloverLineAnnotation.y1 = 0
        this.rolloverLineAnnotation.y2 = translateToNotScaled(this.parentSurface.seriesViewRect.bottom)
      }
    }
  }

  getRolloverProps(rs: IRenderableSeries) {
    return rs.rolloverModifierProps
  }

  CalculateTooltipPositions(
    tooltipArray: any,
    allowTooltipOverlapping: any,
    spacing: any,
    seriesViewRect: any,
    pixelRatio: any,
  ) {
    return this.calcTooltipPositions(tooltipArray, allowTooltipOverlapping, spacing, seriesViewRect, pixelRatio, false)
  }

  calcTooltipPositions(
    tooltipArray: any,
    allowTooltipOverlapping: any,
    spacing: any,
    seriesViewRect: any,
    pixelRatio: any,
    isVerticalChart: boolean,
  ) {
    if (isVerticalChart === void 0) {
      isVerticalChart = false
    }
    const positionProperties = getTooltipPositionProperties(isVerticalChart)
    const hasOverlap = checkHasOverlap(tooltipArray, spacing, pixelRatio, positionProperties)
    const length = tooltipArray.length
    if (!allowTooltipOverlapping && length >= 2 && hasOverlap) {
      const spreadMap_1 = spreadTooltips(tooltipArray, pixelRatio, positionProperties, spacing, seriesViewRect)
      tooltipArray.forEach((tooltip: { [x: string]: number | undefined; index: number }) => {
        tooltip[positionProperties.shiftPropertyName] = spreadMap_1.get(tooltip.index)
      })
    }
    return tooltipArray
  }

  applyTheme(themeProvider: any) {
    if (this.parentSurface) {
      const previousThemeProvider = this.parentSurface.previousThemeProvider
      if (this.rolloverLineAnnotation.stroke === previousThemeProvider.cursorLineBrush) {
        this.rolloverLineAnnotation.stroke = themeProvider.cursorLineBrush
      }
    }
  }

  hitTestRenderableSeries(rs: IRenderableSeries, mousePoint: any) {
    if (!mousePoint) {
      return undefined
    }
    if (this.hitTestRadius <= 0) {
      return rs.hitTestProvider.hitTestXSlice(mousePoint.x, mousePoint.y)
    } else {
      return rs.hitTestProvider.hitTestDataPoint(mousePoint.x, mousePoint.y, this.hitTestRadius)
    }
  }

  addSeriesAnnotationsToParentSurface(rs: IRenderableSeries) {
    if (
      !this.parentSurface ||
      rs.type === ESeriesType.StackedMountainCollection ||
      rs.type === ESeriesType.StackedColumnCollection
    ) {
      return
    }
    this.getRolloverProps(rs).rolloverModifier = this
    createAnnotations(rs, this.getRolloverProps(rs), this.placementDivIdProperty)
    this.parentSurface.modifierAnnotations.add(this.getRolloverProps(rs).marker)
    this.parentSurface.modifierAnnotations.add(this.getRolloverProps(rs).tooltip)
  }

  getMousePosition() {
    return this.mousePosition
  }

  createLine(options: any) {
    let _a, _b
    return new LineAnnotation({
      xCoordinateMode: ECoordinateMode.Pixel,
      yCoordinateMode: ECoordinateMode.Pixel,
      strokeDashArray: options === null || options === void 0 ? void 0 : options.rolloverLineStrokeDashArray,
      strokeThickness:
        (_a = options === null || options === void 0 ? void 0 : options.rolloverLineStrokeThickness) !== null &&
        _a !== void 0
          ? _a
          : 2,
      stroke:
        (_b = options === null || options === void 0 ? void 0 : options.rolloverLineStroke) !== null && _b !== void 0
          ? _b
          : SciChartSurfaceBase.DEFAULT_THEME.cursorLineBrush,
      xAxisId: this.xAxisId,
      yAxisId: this.yAxisId,
    })
  }

  onAttach() {
    const _this = this
    super.onAttach.call(this)
    this.addLineAnnotationToSurface()
    // this.parentSurface.modifierAnnotations.add(this.legendAnnotation)
    this.getIncludedRenderableSeries().forEach(function (rs) {
      return _this.addSeriesAnnotationsToParentSurface(rs)
    })
  }

  addLineAnnotationToSurface() {
    this.parentSurface.modifierAnnotations.add(this.rolloverLineAnnotation)
  }

  onDetach() {
    const _this = this
    super.onDetach.call(this)
    this.parentSurface.modifierAnnotations.remove(this.rolloverLineAnnotation, true)
    // this.parentSurface.modifierAnnotations.remove(this.legendAnnotation)
    this.getIncludedRenderableSeries().forEach(function (rs) {
      return _this.removeSeriesAnnotationsFromParentSurface(rs)
    })
  }

  removeSeriesAnnotationsFromParentSurface(rs: IRenderableSeries) {
    if (!this.parentSurface) return
    this.parentSurface.modifierAnnotations.remove(this.getRolloverProps(rs).marker)
    this.parentSurface.modifierAnnotations.remove(this.getRolloverProps(rs).tooltip)
    this.getRolloverProps(rs).delete()
  }
  // toJSON() {
  //   const json = super.toJSON.call(this)
  //   const options = {
  //     placementDivId: this.placementDivIdProperty,
  //     hitTestRadius: this.hitTestRadius,
  //     showTooltip: this.showTooltip,
  //     tooltipDataTemplate: this.typeMap.get('tooltipDataTemplate'),
  //   }
  //   Object.assign(json.options, options)
  //   return json
  // }
}

const getTooltipPositionProperties = function (isVerticalChart: boolean) {
  if (isVerticalChart) {
    return {
      sizePropertyName: ESize.width,
      coordPropertyName: ECoord.xCoord,
      shiftPropertyName: EShift.xCoordShift,
    }
  } else {
    return {
      sizePropertyName: ESize.height,
      coordPropertyName: ECoord.yCoord,
      shiftPropertyName: EShift.yCoordShift,
    }
  }
}

const createAnnotations = function (
  rs: IRenderableSeries,
  rolloverModifierProps: RolloverModifierRenderableSeriesProps,
  placementDivId: string,
) {
  let _a, _b, _c, _d
  if (!rolloverModifierProps.marker) {
    rolloverModifierProps.marker = new RolloverMarkerSvgAnnotation(rolloverModifierProps)
    // Rollover tooltips for multiple Y-Axes are not supported for stacked series
    if (!rs.isStacked) {
      rolloverModifierProps.marker.xAxisId = rs.xAxisId
      rolloverModifierProps.marker.yAxisId = rs.yAxisId
    }
  }
  if (!rolloverModifierProps.tooltip) {
    rolloverModifierProps.tooltipTitle =
      (_b = (_a = rolloverModifierProps.tooltipTitle) !== null && _a !== void 0 ? _a : rs.getDataSeriesName()) !==
        null && _b !== void 0
        ? _b
        : ''
    rolloverModifierProps.tooltipColor = getColor('--tooltip-bg-color') //rolloverModifierProps.tooltipColor
    // rolloverModifierProps.shadowColor = getColor('--tooltip-bg-color')
    rolloverModifierProps.tooltip = new RolloverTooltipSvgAnnotation(rolloverModifierProps, {
      seriesType: rs.type,
      placementDivId: placementDivId,
    })
    rolloverModifierProps.tooltip.width = 6
    rolloverModifierProps.tooltip.xAxisId = rs.xAxisId
    rolloverModifierProps.tooltip.yAxisId = rs.yAxisId
  }
}

const calcTooltipProps = function (
  index: number,
  rs: IRenderableSeries,
  rolloverProps: RolloverModifierRenderableSeriesProps,
  seriesViewRect: any,
  xValue: number,
  yValue: number,
  absoluteXCoord: number,
  absoluteYCoord: number,
  hitTestInfo: any,
  pixelRatio: number,
  isY1: boolean,
  isVerticalChart: boolean,
) {
  const visibleRange = rs.yAxis.visibleRange
  const isVisible = visibleRange.min <= yValue && yValue <= visibleRange.max
  if (isVisible) {
    const seriesInfo = rs.getSeriesInfo(hitTestInfo)
    const width = rolloverProps.tooltip.width
    const scaledWidth = width * pixelRatio
    const height = rolloverProps.tooltip.height
    const scaledHeight = height * pixelRatio
    const distTop = absoluteYCoord
    const distBottom = seriesViewRect.height - absoluteYCoord
    const defaultVerticalShift = 5 * pixelRatio
    // const xCoordShift = absoluteXCoord < scaledWidth ? 0 : 5 - width
    const xCoordShift = seriesViewRect.width - absoluteXCoord < scaledWidth ? -width : 5
    let yCoordShift = isVerticalChart ? defaultVerticalShift : -height / 2
    if (isVerticalChart) {
      if (distBottom < scaledHeight + defaultVerticalShift) {
        yCoordShift = ((scaledHeight + defaultVerticalShift) / pixelRatio) * -1
      }
    } else {
      if (distTop < scaledHeight / 2) {
        yCoordShift = -distTop / pixelRatio
      } else if (distBottom < scaledHeight / 2) {
        yCoordShift = -(scaledHeight - distBottom) / pixelRatio
      }
    }

    const newRecord = {
      index: index,
      isY1: isY1,
      xValue: xValue,
      yValue: yValue,
      xCoord: absoluteXCoord,
      yCoord: absoluteYCoord,
      hitTestPointValues: hitTestInfo.hitTestPointValues,
      isCategoryAxis: hitTestInfo.isCategoryAxis,
      xCoordShift: xCoordShift,
      yCoordShift: yCoordShift,
      height: scaledHeight,
      width: scaledWidth,
      seriesInfo: seriesInfo,
    }
    return newRecord
  }
  // return undefined;
}

const AUTO_COLOR = 'auto'
const updateRolloverModifierProps = function (
  rolloverRSProps: any,
  rs: any,
  tooltipProps: any,
  showTooltip: boolean,
  showMarker: boolean,
  placementDivId: string,
  height: number,
) {
  rolloverRSProps.tooltip.seriesInfo = tooltipProps.seriesInfo
  if (tooltipProps.isY1) {
    rolloverRSProps.tooltip.seriesInfo.isFirstSeries = false
  }
  if (showMarker) {
    rolloverRSProps.marker.isHidden = false
    rolloverRSProps.marker.x1 = tooltipProps.xValue
    rolloverRSProps.marker.y1 = tooltipProps.yValue
    if (rolloverRSProps.markerColor.startsWith(AUTO_COLOR)) {
      rolloverRSProps.markerColor = tooltipProps.isY1 ? rs.strokeY1 : rs.stroke
    }
  }
  // Update tooltips
  if (showTooltip) {
    rolloverRSProps.tooltip.isHidden = false
    rolloverRSProps.tooltip.x1 = tooltipProps.xValue
    rolloverRSProps.tooltip.y1 = tooltipProps.yValue
    rolloverRSProps.tooltip.xCoordShift = tooltipProps.xCoordShift
    rolloverRSProps.tooltip.yCoordShift = (height - tooltipProps.yCoord - 70) / DpiHelper.PIXEL_RATIO // tooltipProps.yCoordShift
    if (rolloverRSProps.tooltipColor.startsWith(AUTO_COLOR)) {
      rolloverRSProps.tooltipColor = rs.stroke
    }
  } else {
    if (placementDivId) {
      rolloverRSProps.tooltip.delete()
    }
  }
}

// const STROKE = 'STROKE'
// const STROKE_THICKNESS = 'STROKE_THICKNESS'

// Object.defineProperty(TooltipModifier.prototype, 'rolloverLineStroke', {
//   /** Gets or Sets the color of the vertical rollover line as an html color code */
//   get: function () {
//     return this.rolloverLineAnnotation.stroke
//   },
//   /** Gets or Sets the color of the vertical rollover line as an html color code */
//   set: function (rolloverLineStroke) {
//     this.rolloverLineAnnotation.stroke = rolloverLineStroke
//     this.notifyPropertyChanged(STROKE)
//   },
//   enumerable: false,
//   configurable: true,
// })

// Object.defineProperty(TooltipModifier.prototype, 'rolloverLineStrokeThickness', {
//   /** Gets or Sets the thickness of the vertical rollover line */
//   get: function () {
//     return this.rolloverLineAnnotation.strokeThickness
//   },
//   /** Gets or Sets the thickness of the vertical rollover line */
//   set: function (rolloverLineStrokeThickness) {
//     this.rolloverLineAnnotation.strokeThickness = rolloverLineStrokeThickness
//     this.notifyPropertyChanged(STROKE_THICKNESS)
//   },
//   enumerable: false,
//   configurable: true,
// })
