import { FC, Suspense, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PatientHeader, Toolbar } from '.'
import { Info } from '..'
import { AppState, DataRange, DateRange, DateRangeTimestamp, Patient, UsageSegment } from '../../types'
import { TimeAxis, Navigator, VitalSurface } from '../chart'
import {
  useUsageSegments,
  useActivePatient,
  usePatient,
  useDeviceGroups,
  usePatientService,
  useWard,
  useDisplayContext,
  useFirebaseRoutesAnalytics,
  useWasmSetting,
  useDeviceStatusSubscription,
} from '../../hooks'
import { NoteSurface } from '../chart/NoteSurface'
import { INoteService, IPatientService } from '../../services'
import { useNoteService } from '../../hooks/useNoteService'
import { useSelector } from 'react-redux'
import { uiSlice } from '../../store'
import { useDispatch } from 'react-redux'
import { SciTimeAxis, SciNavigator } from '../scichart'

export const PatientPage: FC = () => {
  const { patientId, wardId } = useParams()
  const { data } = useWard(wardId ? parseInt(wardId) : -1)
  const dispatch = useDispatch()
  const service = usePatientService(patientId)
  const noteService = useNoteService(patientId)
  useDeviceStatusSubscription(patientId)
  useFirebaseRoutesAnalytics()

  useEffect(() => {
    return () => {
      const start = useDisplayContext().start.getTime()
      dispatch(uiSlice.actions.setDataRange({ value: { start } }))
    }
  }, [])

  if (!data || data.activePatientIds.length < 1) return <Info text="Loading ..." />
  if (!patientId || !service || !noteService) return <Info text="Invalid patientId" />
  if (!data.activePatientIds.find((x) => x === patientId)) return <Info text="No patient data" />

  return <PatientContentContainer patientId={patientId} service={service} noteService={noteService} />
}

const PatientContentContainer: FC<{ patientId: string; service: IPatientService; noteService: INoteService }> = ({
  patientId,
  service,
  noteService,
}) => {
  const dataRange = useSelector<AppState, DataRange>((state) => state.ui.dataRange)
  if (!dataRange.value.start) {
    return <Info text="loading ..." />
  }

  return (
    <PatientContent
      patientId={patientId}
      service={service}
      noteService={noteService}
      dataRange={{
        start: new Date(dataRange.value.start),
        end: dataRange.value.end ? new Date(dataRange.value.end) : undefined,
      }}
    />
  )
}

const PatientContent: FC<{
  patientId: string
  dataRange: DateRange
  service: IPatientService
  noteService: INoteService
}> = ({ patientId, dataRange, service, noteService }) => {
  const usageSegments = useUsageSegments(patientId, dataRange.start, dataRange.end)
  const patient = usePatient(patientId, true)
  const { wasmChartEnabled } = useWasmSetting()

  if (!patient) {
    return <Info text="Error getting patient info" />
  }

  return (
    <div className="v-contentView">
      <div className="v-contentArea">
        <PatientHeader patient={patient} />
        <div className="v-layout">
          <div className="v-patientPanel">
            <Suspense fallback={<Info text="Loading ..." />}>
              {wasmChartEnabled ? (
                <SciTimeAxis id="vital-time-axis" showControl={true} />
              ) : (
                <TimeAxis id="intervention-time-axis" showControl={true} />
              )}
            </Suspense>
            {noteService ? <NoteSurface patient={patient} service={noteService} /> : undefined}
            <PatientDeviceView patient={patient} usageSegments={usageSegments} service={service} />
            <div className="v-patientPanel-footer">
              <Suspense fallback={<Info text="Loading ..." />}>
                {wasmChartEnabled ? (
                  <>
                    <SciTimeAxis id="vital-time-axis" showControl={true} />
                    <SciNavigator patient={patient} service={service} showControl={true} />
                  </>
                ) : (
                  <>
                    <TimeAxis id="vital-time-axis" showControl={true} />
                    <Navigator patient={patient} service={service} showControl={true} />
                  </>
                )}
              </Suspense>
            </div>
          </div>
          <div style={{ width: '20px' }}></div>
          <Toolbar patient={patient} />
        </div>
      </div>
    </div>
  )
}

const PatientDeviceView: FC<{ patient: Patient; usageSegments: UsageSegment[]; service: IPatientService }> = ({
  patient,
  usageSegments,
  service,
}) => {
  const activePatient = useActivePatient(patient.id)
  const deviceGroups = useDeviceGroups(usageSegments, activePatient?.devices)
  if (!deviceGroups) {
    return <Info text="Loading" />
  }

  return (
    <>
      {deviceGroups?.map((group) =>
        group.data.length ? (
          <VitalSurface
            name={group.name}
            key={group.name}
            patient={patient}
            viewModels={group.data}
            service={service}
          />
        ) : null,
      )}
    </>
  )
}
